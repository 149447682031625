@import './Colors.scss';

.ion-page[data-page="select-sector"] {

    ion-toolbar {
        height: 0;
        overflow: hidden;
    }

    #verify-email-wrapper {

        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            &>div {
                text-align: center;
                padding: 0 20px;

                h1 {
                    font-size: 1.8em;
                }

            }

        }

        .sectors {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 500px;

            &>div {
                flex-basis: calc(50% - 7.5px);
                border-radius: 12px;
                margin-bottom: 15px;
                overflow: hidden;

                &:nth-child(odd) {
                    margin-right: 15px;
                }
                
                img {
                    display: block;
                }

                p {
                    margin: 5px 0 5px 0;
                    font-weight: 700;
                    color: white;
                }
            }
        }
        
        button {
            background: none;
            font-weight: 500;
            font-size: 1.1em;
            padding: 16px 24px;
            border-radius: 8px;
            min-width: 140px;
            background-color: $light-blue;
            color: white;

            &.secondary-btn {
                display: block;
                margin: 20px auto 0 auto;
                background-color: whitesmoke;
                color: #555;
            }

        }
    
    }

}