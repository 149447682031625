@import '../../Colors.scss';

.referral-item {

    .details {

        .avatars {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &>div {
                width: 40px;
                height: 40px;
                position: relative;

                &>img {
                    display: block;
                    border-radius: 100%;
                    
                    &.primary {
                        width: 40px;
                        height: 40px;
                    }

                    &.secondary {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        right: -5px;
                        bottom: -5px;
                    }
                }
            }

            ion-icon {
                padding: 0 4px;
            }
        }

        .contact-details {
            padding-left: 16px;
            flex-basis: unset;
            width: unset;

            .headline {
                margin: 0
            }
        }
    }
}