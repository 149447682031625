@import './Colors.scss';

#desktop-wrapper {
     background: #F4F4F4;
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;

     .calendar-wrapper img {
          display: none;
     }

     ion-tabs {

          ion-tab-button {
               padding-right: 0 !important;

               .badge {
                    top: 7px !important;
                    right: 2px !important;
                    width: 11px !important;
               }
          }
     }
     
     .desktop-wrapper-inner {
          max-width: 1000px;
          height: 100vh;
          width: 100%;
          margin: auto;
     }

     .side-header {
          height: 56px;
          background-color: $light-blue-2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          z-index: 2;
          position: absolute;
          width: 140px;

          &>img {
               width: 44px;
          }

          h1 {
               margin: 0;
               font-size: 1.2em;
               color: white;
          }

          .profile-pic {
               background-color: white;
               width: 40px;
               height: 40px;
               border-radius: 100%;
               overflow: hidden;

               img {
                    width: 100%;
               }

          }

     }

     ion-app {
          max-width: 1000px;
          height: 100vh;
          width: 100%;
          margin: auto;

          .bottom-fab-options {
               max-width: 400px;
               left: 10px;
               right: auto;
          }

          .onboarding-guide-prompt {
               max-width: 400px;
          }

          .ion-page {
               border-left: 3px solid #dadada;
          }

          &>.ion-page {
               border: none;
          }    

          .desktop-split {
               border-radius: 12px;
               overflow: hidden;
               position: absolute;
               width: 100%;
               height: 100%;
               display: flex;

               .left {
                    flex-basis: 359px;
                    border-right: 1px solid #cecece;
               }

               .right {
                    flex-basis: calc(100% - 360px);

                    .side-navigation {
                         background-color: white;
                         height: 64px;
                         
                         .links {
                              height: 64px;
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              text-align: center;

                              a {
                                   height: 64px;
                                   position: relative;
                                   display: inline-flex;
                                   align-items: center;
                                   justify-content: center;
                                   flex-basis: 25%;
                                   text-decoration: none !important;
                                   color: #333 !important;
                                   border-bottom: 6px solid transparent;

                                   ion-icon {
                                        padding-right: 8px;
                                        font-size: 1.4em;
                                   }

                                   div {
                                        text-align: left;
                                   }

                                   span {
                                        font-size: 1em;
                                   }

                                   p {
                                        display: none;
                                        margin: 0;
                                   }

                                   &[data-active="true"] {
                                        border-bottom: $light-blue-2 4px solid !important;
                                   }

                                   .badge {
                                        left: 22px;
                                        right: auto;
                                        top: 8px;
                                        line-height: 15px !important;
                                        font-size: 15px !important;
                                        padding: 0;
                                        width: 24px;
                                        text-align: center;
                                        height: 24px;
                                        display: inline-flex;
                                        align-items: center;
                                        justify-content: center;
                                   }

                              }

                         }

                    }

                    .ion-page {
                         opacity: 1;
                         width: calc(100% - 360px);
                         left: 360px;
                         right: auto;
                         top: 64px;
                         height: calc(100% - 64px);

                         .tab-header {
                              display: none;
                         }

                    }

               }

          }

     }

     // ion-tab-bar {
     //      display: none;
     // }

     ion-tabs {
          flex-direction: row-reverse !important;
          background-color: white;

          ion-tab-bar {
               flex-basis: 160px !important;
               flex-wrap: wrap;
               contain: inherit;
               align-self: flex-start;
               margin-top: 56px;
               border: none;

               ion-tab-button {
                    display: flex;
                    flex-basis: 100%;
                    padding: 0 8px;
                    flex-direction: row;
                    justify-content: flex-start !important;
                    height: 40px !important;
                    border-bottom: 1px solid #bbb;

                    ion-label {
                         font-size: 16px;
                    }

                    ion-icon {
                         padding-right: 8px;
                    }

                    &:first-child {
                         border-top: 1px solid #bbb;
                    }

                    &.tab-selected {
                         background-color: $light-blue-2 !important;
                         color: white;
                    }
               }
          }

          .tabs-inner {
               flex-basis: calc(100% - 160px) !important;
          }    
     }

     // ::-webkit-scrollbar {
     //      display: none;
     // }

     &[data-url*="/onboarding"] {
          padding: 0;
          height: 100%;
          width: 100%;

          ion-app {
               height: 100%;
               width: 100%;
               max-width: unset;
          }

          .side-header {
               display: none;
          }
     }

     &[data-url*="/calendar"] {

          .side-navigation {
               height: 100% !important;

               .links {
                    height: 100% !important;
                    flex-wrap: wrap;

                    a {
                         flex-basis: 100% !important;
                         height: 100px !important;

                         ion-icon {
                              font-size: 2.8em !important;
                         }

                         div {
                              padding-left: 12px;
                              min-width: 320px;
                              position: relative;

                              span {
                                   font-weight: 600;
                                   font-size: 1.4em !important;
                                   line-height: 1.4em !important;
                              }

                         }

                         p {
                              display: block !important;
                         }

                         .badge {
                              top: 10px !important;
                              left: -28px !important;
                              transform: translate(0, -50%);
                         }

                    }

               }
               
          }

     }

     &[data-url*="/push"],
     &[data-url*="/verify-email"]
     {
          ion-app {
               margin: 0 !important;
               max-width: 100% !important;
               width: 100% !important;
               height: 100% !important;
               padding: 0 !important;
          }
     }

}

@media (min-width: 720px) {
     .profile-progress-wrapper,
     .rating-wrapper,
     .contact-rating-wrapper {
          .MuiPaper-root {
               width: 600px;
               margin: 20px auto !important;
               max-height: calc(100% - 40px) !important;
          }

          &.contact-rating-wrapper,
          &.rating-wrapper
          {

               // .MuiPaper-root {
               //      top: 0;
               //      bottom: auto;
               // }
          }

          .hirer-rater h1 {
               display: none;
          }
     }
}