.location-preview {
    position: relative;
    
    .img {
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        button {
            position: absolute;
            bottom: 15px;
            right: 15px;
            background-color: #333;
            color: white;
            border-radius: 5px;
            padding: 8px 12px;
            font-size: 1.1em;
            display: inline-flex;
            align-items: center;

            ion-icon {
                position: static;
                margin-right: 8px;
            }

        }

        img {
            display: block;
        }

    }

    ion-icon {
        position: absolute;
        top: -17px;
        right: 0;
        font-size: 1.4em;
    }

    .location-name {

        span {
            font-weight: 700;
        }
    }
}