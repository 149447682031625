@import '../../Colors.scss';

.swipeable-drawer-body[data-drawer="offer-edit"] {

    h1 {
        margin: 15px 45px 15px 15px;
        font-weight: 700;
        font-size: 1.2em;
        color: black;
    }

    #offer-edit-close-btn {
        position: absolute;
        color: black;
        font-size: 1.8em;
        top: 0;
        right: 0;
        margin: 12px;
        z-index: 100;
    }

    h2, p, .obligations {
        color: black;
    }

    h2 {
        font-size: 1.1em;
        line-height: 1.16em;
        font-weight: 500;
        margin: 0;
        margin-bottom: 2px;
        margin-top: 20px;
        padding: 0 20px
    }

    h3 {
        font-weight: 700;
    }

    .obligations {
       padding: 20px;
       font-style: italic;
       text-align: center;
    }

    .contact-details {

        h2 {
            padding-left: 0;
        }

    }

    .add-agency-btn {
        background: $light-blue;
        border-radius: 52px;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 1em;
        color: white;
        margin: 20px 0 0 20px;
        display: block;
    }

    .switch[data-reduced="true"] ion-item {
        align-items: flex-start;

        ion-toggle {
            margin-top: 12px;
        }

    }

}
