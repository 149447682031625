@import '../Colors.scss';

.tips-guide-prompt {
    display: flex;
    align-items: center;
    height: 100%;

    section {
        width: 100% !important;

        .details {
            max-width: 300px;
            margin: auto;
            border-radius: 24px !important;
        }
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0 0 28px 0 !important;
    }

    p {
        margin-top: -16px !important;
        margin-bottom: 32px !important;
    }

    .marginless-image {
        margin-left: -15px;
        margin-right: -15px;
        position: relative;

        img {
            display: block;
            position: relative;
            z-index: 2;
        }

        .own-profile-image {
            position: absolute;
            border-radius: 100%;
            background-color: white;
        }
    }

    .or {
        display: flex;
        align-items: center;
        padding: 12px 0;

        .or-line {
            width: 100%;
            height: 1px;
            background-color: #666;
            margin: 0 1rem;
        }

        .or-text {
            font-size: 1rem;
            font-weight: 500;
            color: #666;
            margin: 0;
        }
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .step-number {
            font-weight: 700;
            font-size: 2em;
        }

        .step-description {
            font-size: 1em;
            line-height: 1.2em;
            font-weight: 500;
            margin: 0;
            padding-left: 20px;
        }
    }

    .step-img {
        border-radius: 16px;
        overflow: hidden;

        img {
            width: 100%;
            display: block;
        }
    }

    .buttons {
        margin-top: 16px;
        margin-bottom: 0 !important;

        :not(.main) {
            background-color: transparent;
            color: black;
            margin-bottom: 0;
        }

        .main {
            margin-top: 0;
            border-radius: 52px;
        }
    }

    section[data-section="proposed-resolutions"] {

        .details div p {
            margin: 0 0 10px 0 !important;
            text-align: left;

            span {
                font-weight: 700;
                font-size: 1.2em;
            }
        }
    }
}

.hide-forever-btn {
    position: absolute;
    z-index: 100002;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 700;
    color: white;
    background-color: #6d6d6d;
    border-radius: 52px;
    padding: 6px 12px;
}