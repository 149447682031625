@import '../../Colors.scss';

.rating-preview-extended {
    // background-color: whitesmoke;
    // padding: 15px 15px 12px 15px;
    // border-radius: 10px;
    // margin: 0 10px 10px 10px !important;
    padding: 8px 8px 4px 8px;
    border-bottom: 1px solid #e0e0e0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {

            &>div:first-child>div>span {
                line-height: 24px;
            }

            .bottom-right {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
    
                .date {
                    font-size: 14px;
                    line-height: 14px;
                }
    
                button {
                    background-color: grey;
                    color: white;
                    padding: 0 2px;
                    padding: 2px 5px;
                    border-radius: 4px;
                }
            }
        }

        .react-stars {
            min-width: 124px;
        }
    
        .profile {
            display: inline-flex;
            align-items: center;
            overflow: hidden;
    
            .rater-profile,
            .recipient-profile
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
    
                img {
                    width: 28px;
                    height: 28px;
                    border-radius: 100%;

                    &.secondary {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 20px;
                        bottom: 1px;
                    }
                }
    
                h2 {
                    margin: 0;
                    font-size: 1em;
                    // margin-left: 10px;
                    font-weight: 700;
    
                    span {
                        display: block;
                    }
                }
            }
    
            .rated {
                padding: 0 15px;
                font-weight: 300;
                font-style: italic;
            }
        }
    
        p {
            margin: 0;
        }
    }

    .body {

        p {
            margin: 0;
        }

        &[data-published="false"] {
            padding: 10px;
            margin: 0 -8px -4px -8px;

            &[data-own="false"] {
                display: none;
            }
        }
    }

    &[data-published="false"] {
        background-color: #ff63628f;
    }

    .options {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            font-weight: 300;
            font-style: italic;
            font-size: 0.86em;
            line-height: 1.2em;
            display: block;
            padding-right: 10px;
        }

        &>div {
            display: inline-flex;
        }

        button {
            background-color: $light-blue-2;
            color: white;
            border-radius: 6px;
            padding: 6px 8px;
            font-size: 14px;
            display: block;
            margin-right: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: unset;

            ion-icon {
                margin-right: 4px;
                font-size: 20px;
            }
            
            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    &:last-child {
        border-bottom-width: 0;
    }
}