@import '../../Colors.scss';

.document-viewer-wrapper {

    .bottom-options {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;

      button {
        border: none;
        padding: 8px 12px;
        border-radius: 12px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        margin: 0 5px;

        &.external-link {
          background-color: $red;
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0 0 0 15px;
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    
        button {
          border: none;
          padding: 8px 12px;
          border-radius: 12px;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
    
          ion-icon {
            margin-right: 4px;
          }
        }

        ion-icon {
            font-size: 28px;
            margin-left: 10px;
            align-self: flex-end;
        }

      } 
    } 

    .bottom-options {

      button {
        padding: 12px;
      }
    }

    .img {
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
    }

    .video {
      width: 100%;

      video {
        width: 100%;
        display: block;
        max-height: calc(100vh - 240px);
      }
    }
  
    #react-doc-viewer {
      min-height: 600px;
    }
  
    iframe {
      display: block;
    }
  
    .link-warning {
      text-align: center;
      font-size: 1.4em;
      padding: 0 20px 12px 20px;
      line-height: 1.4em;
      word-break: break-word;

      strong {
        color: $red;
      }
    }

}