@import '../../Colors.scss';

.rating-collector {
    padding: 20px 12px;
    position: relative;
    
    h1 {
        color: #222;
        font-size: 1em;
        line-height: 1.32em;
        text-align: center;
        font-weight: 700;
        margin: 0 0 8px 0;
        padding: 0 32px;

        ion-icon {
            color: #222;
            font-size: 1.4em;
            position: absolute;
            right: 16px;
            top: 30px;
        }

    }

    .timeline {
        margin: 0;
        padding: 0;
        padding-bottom: 32px;
    }

    section {
        margin: 8px 0 36px 0;

        h2 {
            color: #333;
            font-size: 1em;
            line-height: 1.32em;
            text-align: center;
            font-weight: 500;
            margin: 16px 0 8px 0;
        }
        
        .profile-img {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            display: block;
            margin: auto;
            text-align: center;

            img {
                width: 100%;
                display: block;
                box-shadow: $default-box-shadow-2;
                border-radius: 100%;
            }

        }

        .free-text {
            margin-top: 12px;

            textarea {
                background-color: #F2F2F2;
                color: #222;
                margin: auto;
                width: 240px;
                resize: none;
                border: none;
                display: block;
                text-align: center;
                padding: 12px;
                border-radius: 16px;
            }

        }

        .questions {

            .question {

                h3 {
                    color: #333;
                    font-size: 0.86em;
                    line-height: 1.12em;
                    text-align: center;
                    font-weight: 400;
                    margin: 16px 0 8px 0;
                }

                .ratings-wrapper {
                    margin: auto;
                    text-align: center;

                    .MuiRating-root {
                        font-size: 2.2rem;
                    }

                }

            }

        }

    }

    .submit-btn {
        background-color: $light-blue-2;
        padding: 16px 20px;
        color: white;
        display: block;
        width: 100%;
        border-radius: 8px;
    }

}   