@import '../../Colors.scss';

.group div[data-indent="true"] {

    .contact-item:not([data-highlighted="true"]) {
        margin-left: 36px !important;
    }
}

.company-header-item, .contact-item {
    z-index: 1;

    .tags {
        padding: 0;

        .avg-rating {
            font-size: .9em;
            background-color: #3d72e7;
            color: #fff;
            padding: 0px 6px;
            border-radius: 4px;
            margin-left: 6px;

            min-width: 104px;
            text-align: center;
                
            ion-icon {
                font-size: 12px;
                padding-right: 6px;
            }
        }
        
        &>span {
            display: inline-flex;
            align-items: center;

            span {
                font-size: 12px;
                line-height: 12px;
            }

            .rating-preview {
                padding: 0 0 0 8px;
            }

            img:not(.verification-badge):not(.relationship-status) {
                width: 16px !important;
                padding: 0;
                height: auto;
                border-radius: 0 !important;
                display: inline-block !important;
                margin-right: 6px;
            }

            .rating-preview {
                padding-left: 0 !important;
            }

            .stars {

                span {
                    line-height: 20px;
                }
            }
        }
    }
}

.company-header-item {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 8px 0 4px 0;

    img:not(.verification-badge):not(.relationship-status) {
        display: inline-flex;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        padding: 2px;
    }

    .verification-badge, .relationship-status {
        width: 16px !important;
        padding: 0;
        height: auto;
        border-radius: 0 !important;
        display: inline-block !important;
        margin-left: 8px;
        position: relative;
        top: 2px;

        &.relationship-status {
            width: 14px !important;
            padding: 0 !important;
            height: unset !important;
        }
    }

    .rating-preview {
        padding-left: 0 !important;
    }

    div {
        padding: 0 12px;

        h2 {
            margin: 0;
            font-size: 1.1em;
            font-weight: 600;
        }

        p {
            margin: 4px 0 0 0;
            font-size: 0.9em;
            line-height: 1.3em;
            font-weight: 400;
        }

    }

    ion-icon {
        font-size: 1.6em;
    }

    &:nth-child(2) {
        padding-top: 10px;
    }

    &[data-highlighted="true"] {
        background-color: $amber;
        padding-left: 20px !important;
        margin-left: 0;
        padding-top: 0;
    }
}

section[data-section="ratings"] {

    &>h2 {
        font-size: 1.1em;
        line-height: 1.16em;
        font-weight: 500;
        margin: 16px 0 8px 0;
        text-align: center;

        ion-icon {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 24px;
            padding: 10px;
        }
    }

    &>p {
        text-align: center;
    }

    &>button {
        width: calc(100% - 30px);
        background-color: $light-blue-2;
        color: white;
        border-radius: 52px;
        padding: 10px;
        border: none;
        margin: 0 15px 15px 15px;
        font-weight: 700;
    }

    .rater-profile>h2 {
        margin-left: 18px !important;
    }
}


.contact-item-blocking-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: end;

    p {
        color: white;
        display: inline-block;
        padding: 4px 10px;
        background-color: $light-blue-2;
        border-radius: 52px;
        margin-right: 15px;
    }
}


.contact-item {
    margin-left: 20px;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    position: relative;

    &[data-highlight-block="true"] {
        background-color: $light-blue-2;
        padding: 10px;
        border-radius: 10px;
        margin-right: 10px;
        
        h2, p {
            color: white !important;
        }
    }

    .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .blocking-wrapper {
            width: 100%;
            height: 100%;
        }

        .new-dot, .online-dot {
            position: absolute;
            background-color: $light-blue-2;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
        }

        .online-dot {
            background-color: $green;
            right: auto;
            left: -5px;
            top: 3px;
            width: 16px;
            height: 16px;
            z-index: 1;
            box-shadow: $default-box-shadow;
            border: 3px solid white;
        }

        ion-icon {
            flex-basis: 32px;
            font-size: 1.6em;
            padding-right: 12px;
        }

        .picture {
            flex-basis: 44px;
            height: 44px;
            width: 44px;
            border-radius: 100%;
            position: relative;
            min-width: 44px;

            img {
                width: 44px;
                height: 44px;
                display: block;
                border-radius: 100%;
                object-fit: cover;

                &.company-img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    z-index: 2;
                    right: -4px;
                    bottom: -4px;
                    border-radius: 3px;
                }

            }

        }

        .contact-details {
            flex-basis: calc(100% - 52px);
            width: calc(100% - 52px);
            padding-left: 12px;
            padding-right: 12px;
            word-break: break-word;
            overflow: hidden;

            .already-sharing {
                background-color: $green;
                color: white;
                padding: 2px 6px;
                border-radius: 4px;
                display: inline-block;
                font-size: 12px;
                margin-left: 8px;
                position: relative;
                top: -3px;
            }

            h2 {
                font-size: 1em;
                line-height: 1.2em;
                font-weight: 500;
                margin: 0;
                margin-bottom: 2px;
                color: black;
                overflow-wrap: break-word;

                img {
                    width: 16px !important;
                    padding: 0;
                    height: auto;
                    border-radius: 0 !important;
                    display: inline-block !important;
                    margin-left: 6px;
                    position: relative;
                    top: 2px;
                }
            }

            p {
                margin: 0;
                font-size: 0.86em;
                line-height: 1.1em;
                font-weight: 300;
                margin-bottom: 2px;
                color: black;
                overflow-wrap: break-word;

                &.headline {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            
            }

            .status {
                display: flex;
                align-items: center;
                justify-content: left;

                ion-icon {
                    padding-right: 4px;
                    flex-basis: 20px;
                }

                span {
                    display: inline-block;
                    flex-basis: calc(100% - 20px);
                }

            }

        }

    }

    .options {
        margin-top: 6px;
        padding-left: 56px;

        button {
            padding: 8px 20px 7px 20px;
            border-radius: 8px;
            margin-right: 8px;
            display: inline-block;
            color: white;
            text-transform: uppercase;
            font-size: 0.8em;

            &.reject-btn, &.cancel-btn {
                background-color: $red;
            }

            &.accept-btn {
                background-color: $green;
            }

        }

    }

    &:last-child {
        border-bottom: none;
    }

    &[data-highlighted="true"] {
        background-color: $amber;
        padding-left: 36px !important;
        margin-left: 0 !important;
    }
}