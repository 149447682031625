@import '../Colors.scss';

.profile-progress-wrapper, .upgrade-wrapper {

    ion-textarea {
        margin: 0;
    }

    ion-item {
        border-radius: 16px;
    }
    
    .no-items {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;

        &>div {

            ion-icon {
                display: block;
                margin: 0 auto 12px auto;
                font-size: 32px;
            }

            p {
                margin: 0;
                font-size: 1.1em;
            }
        }
    }

    .file-access-warn {
        background-color: $amber;
        margin-top: -8px;
        border-radius: 0 0 8px 8px;
        position: relative;
        z-index: 1;
        padding: 15px 10px 8px 10px;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: white;
        margin-bottom: 8px;

        p {
            margin: 0;
            padding-left: 8px;
            width: calc(100% - 24px);
        }

        ion-icon {
            font-size: 20px;
        }
    }

    .inline-select {
        position: relative;
        z-index: 2;
    }

    &.profile-progress-wrapper {

        .swipeable-drawer-body {
            max-height: 100%;
            min-height: 100%;
        }
    }

    .swipeable-drawer-body {
        padding: 5px 15px 15px 15px;
        overflow: hidden;
        background-color: whitesmoke;
        overflow: scroll;

        &>.drawer-wrapper {
            height: 100%;
            min-height: inherit;
            display: flex;
            flex-wrap: wrap;

            &>* {
                flex-basis: 100%;
            }

            &>.header-wrapper {
                height: fit-content;
                // margin-bottom: 15px;
            }

            .open-task {
                align-self: flex-end;
                max-width: 100%;
            }
        }

        .section-pagination {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
            position: relative;

            &>div {
                width: 36px;
                height: 36px;
                border-radius: 100%;
                background-color: gainsboro;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                position: relative;

                ion-icon {
                    font-size: 22px;
                }

                &.close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    background-color: transparent;

                    ion-icon {
                        font-size: 28px;
                    }
                }

                &[data-completed="true"] {
                    background-color: $green;
                    color: white;
                }

                &[data-current="true"] {
                    background-color: $light-blue-2;
                    color: white;
                }

                @media (max-width: 370px) {
                    width: 30px;
                    height: 30px;

                    ion-icon {
                        font-size: 18px;
                    }
                }

                &>.arrow {
                    position: absolute;
                    top: -15px;
                    right: -15px;

                    img {
                        width: 22px;
                    }

                    &[data-reverse="true"] {
                        top: unset;
                        bottom: -15px;
                        transform: rotateX(-180deg);

                    }
                }
            }
        }

        ion-list {
            border-radius: 20px;
            max-height: 290px;
            overflow: scroll;
            
            ion-item {
                --padding-start: 20px;
                --padding-end: 20px;
            }

        }

        h1 {
            position: relative;
            margin: 12px 0px;
            font-size: 1.4em;
            font-weight: 700;
            
            ion-icon {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 24px;
            }
        }

        button:not(.ReactTags__remove) {
            border: none;
            padding: 16px 8px;
            border-radius: 12px;
            background: white;
            color: #333;
            font-size: 0.9em;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                font-size: 20px;
                position: relative;
                margin-right: 8px;
            }

            &.main {
                color: white;
                background-color: $light-blue-2;
                font-weight: 600;
            }

            &.delete {
                color: white;
                background-color: $red;
                font-weight: 600;
            }

            &:not(.main) {
                border: 2px solid #e9e9e9;
            }

        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            button {
                flex-basis: calc(100% - 10px);
                
                &.main {
                    margin-left: 20px;
                }

            }

        }

        .help-guides {

            h2 {
                font-size: 1.2em;
                font-weight: 700;
                margin: 20px 0 10px 0;
            }
            

        }

        .header-progress {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;

            .progress-type {
                width: 86px;

                img {
                    width: 100%;
                    display: block;
                }

                .img {
                    width: 100%;
                    height: 86px;
                    border-radius: 100%;
                    background-color: $light-blue-2;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    ion-icon {
                        color: white;
                        font-size: 52px;
                    }
                }
                
                h2 {
                    font-weight: 700;
                    font-size: 1.2em;
                    line-height: 1.4em;
                    margin: 10px 0 2px 0;
                    text-align: center;
                }

                p {
                    text-align: center;
                    margin: 0;
                    font-size: 1em;
                    line-height: 1.2em;
                }
            }
        }

        .header {
            display: flex;
            margin: 8px 0 8px 0;
            align-items: center;

            .profile-image {
                flex-basis: 74px;
                position: relative;

                .circle {
                    width: 74px;
                    height: 74px;
                    border-radius: 100%;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    right: 0;
                }

                .logo-wrapper {
                    background-color: $light-blue-2;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    width: 74px;
                    height: 74px;

                    img {
                        width: 32px;
                        height: auto;
                    }

                }
                
            }

            .side {
                flex-basis: calc(100% - 74px);
                padding-left: 12px;

                h1 {
                    font-weight: 700;
                    font-size: 1.2em;
                    margin: 4px auto 6px auto;
                    display: none;
                }
        
                h2 {
                    font-weight: 700;
                    font-size: 1em;
                    margin: 0 auto 0 auto;
                }

                ion-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 24px;
                    padding: 18px;
                }
            }

            &.section-header {

                .side {
                    flex-basis: calc(100% - 48px);
                    padding: 0 0 0 12px;
                }

                .back-btn {
                    flex-basis: 48px;

                    ion-icon {
                        font-size: 2.6em;
                        display: block;
                    }

                }

                h1 {
                    padding-left: 4px;
                }

                h2 {
                    margin-bottom: 5px;
                    margin-top: 10px;
                }
            }

        }

    }

    .line-down-container {
        margin: 0 20px;
        position: relative;

        .line {
            position: absolute;
            right: 40px;
            top: -10px;
            width: 8px;
            height: 16px;
            background-color: $light-blue-2;
        }

        button {
            width: 220px !important;
            float: right;
            margin-top: 0px !important;
            background: $light-blue-2 !important;
            color: white !important;
        }
    }

    .todos {
        // overflow: scroll;
        // max-height: 300px;
        background: white;
        border-radius: 20px;
        // padding: 0px 10px;
        margin-bottom: 20px;

        .info {
            padding: 0px 15px 12px 15px;
            margin: 0;
        }

        .tickers {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-bottom: 15px;

            &>div {
                margin: 0 5px;
                flex-basis: 33.3333%;

                h3 {
                    font-size: 1.2em;
                    margin: 0px;
                }

                p {
                    font-size: 0.9em;
                    line-height: 1.1em;
                    margin: 6px 0 0 0;
                }
            }
        }

        h2 {
            margin: 0;
            font-size: 1.2em;
            padding: 15px 100px 5px 15px;
            font-weight: 600;
            position: relative;

            .mini-progress {
                position: absolute;
                right: 15px;
                top: 12px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 8px;
                }

                span {
                    font-size: 18px;
                    line-height: 18px;
                }
            }

            ion-icon {
                position: absolute;
                top: 14px;
                right: 15px;
                color: #333;
                font-size: 24px;
            }
        }

        .step {
            display: flex;
            position: relative;
            align-items: center;
            // padding-bottom: 8px;

            .line {
                position: absolute;
                width: 4px;
                background: #f2f2f2;
                height: 100%;
                left: 18px;
                top: 0;
                z-index: 1;
                display: none;
            }

            .category-badge {
                flex-basis: 40px;
                height: 40px;
                width: 40px;
                // background-color: $light-blue-2;
                position: relative;
                z-index: 2;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                // color: white;
                color: #333;
                border-radius: 100%;
                font-size: 1.1em;
                font-weight: 700;
            }

            .side {
                flex-basis: calc(100% - 72px);
                overflow: hidden;
                padding: 8px 0px;

                h3 {
                    font-size: 1.1em;
                    margin: 0px 0 6px 0;

                    span {
                        font-weight: 300;
                        font-size: 0.86em;
                        margin-left: 8px;
                    }

                }

                p {
                    font-size: 0.9em;
                    line-height: 1.2em;
                    margin: 0;
                }
    
            }

            .action {
                flex-basis: 40px;
                height: 40px;
                width: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                color: white;

                ion-icon {
                    font-size: 1.32em;
                }

            }

            &[data-completed="true"] {
                

            }

        }

        &.help {

        }

        &.contact {
            
            h2 {
                margin: 15px 0 0 0 !important;
            }

        }

        button {
            background: $light-blue-2 !important;
            width: calc(100% - 30px);
            color: white !important;
            margin: 15px;
            border: none !important;
        }

    }

    .open-task {

        .react-tel-input  {
            border-radius: 12px;
            background: white;

            input {
                border: none;
            }

            .flag-dropdown {
                background: white;
                border: none;
            }
        }

        .file-type-selector {

            .type {
                display: flex;
                margin-bottom: 10px;

                .img {
                    flex-basis: 100px;
                }

                .text {
                    display: inline-flex;
                    align-items: center;
                    justify-content: left;
                    padding-left: 10px;
                    flex-basis: calc(100% - 100px);
                    flex-wrap: wrap;

                    div {
                        p:first-child {
                            margin: 0 0 6px 0;
                            flex-basis: 100%;
                            font-weight: 700;
                        }
    
                        p:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .react-tel-input input {
            width: 100%;
        }

        div[data-testid="otp-resend-root"] {
            align-items: center;
            margin-top: 20px;

            button {
                margin-top: 0;
            }
        }

        input[type="tel"]:not(.form-control) {
            background-color: whitesmoke;
            border: #bbb 2px solid;
            border-radius: 12px;
            width: 28px !important;
            height: 28px !important;
            margin-right: 8px !important;
        }

        input[type="tel"]:last-child {
            margin-right: 0 !important;
        }

        .profile-img img {
            width: 64px;
            display: block;
            margin: auto;
            border-radius: 100%;
            height: 64px;
        }

        button.option {
            margin: 10px 15px 5px 15px !important;
            padding: 10px 12px;
            background-color: whitesmoke;
            width: calc(100% - 30px) !important;
        }

        button:not(.ReactTags__remove) {
            width: 100%;
            margin-top: 20px;
        }

        .options {
            display: flex;

            button:first-child {
                margin-right: 5px;
            }

            button:last-child {
                margin-left: 5px;
            }
        }

        .qualifications, .files {

            .file-edited {

                .input-with-icon {
                    display: flex;
                    align-items: center;

                    ion-icon {
                        margin-right: 8px;
                    }

                    svg {
                        width: 18px;
                        margin-right: 7px;
                        
                        path {
                            fill: #333;
                        }
                    }
                }
            }

            select {
                background: transparent;
                width: 100%;
                border: none;
            }
            
            .qualification {
                padding: 16px;
                background: white;
                border-radius: 16px;

                .qualification-head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 8px;

                    // h2 {
                    //     font-size: 1.1em;
                    //     margin: 0;
                    //     font-weight: 700;
                    // }
    
                    .options {
                        text-align: right;
    
                        button {
                            width: unset;
                            padding: 8px 12px;
                            border: none;
                            margin: 0;
                            margin-right: 10px;

                            ion-icon {
                                margin-right: 4px;
                            }

                            &:not(.main):not(.delete) {
                                background-color: whitesmoke;
                            }
                        }
                    }
                }

                .details-preview {

                    h3 {
                        font-size: 1em;
                        font-weight: 700;
                        margin: 0 0 4px 0;
                        line-height: 1.2em;
                    }

                    p {
                        font-size: 0.96em;
                        line-height: 1.1em;
                        margin: 0
                    }
                }

                .details {

                    .field-read, .field .field-head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h3, p {
                            font-size: 1em;
                            margin: 0;
                            line-height: 1.2em;
                        }

                        p {
                            text-align: right;
                            padding-left: 12px;
                        }

                        ion-input {
                            background-color: whitesmoke;
                            --padding-start: 12px !important;
                            border-radius: 8px;
                        }
                    
                        &>ion-input, .ion-input-group {
                            max-width: calc(100% - 124px);
                        }

                        .ion-input-group {
                            display: flex;
                        }
                        
                        &:not(:last-child) {
                            margin-bottom: 4px;
                        }

                        &:not(.field-read) {
                            margin-bottom: 8px;

                            h3 {
                                margin-right: 12px;
                            }
                        }
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }

            &.files {
                max-height: calc(100vh - 280px);
                overflow: auto;
                margin-top: 12px;
            }
        }

    }

}