@import "../../Colors.scss";

.sector-skill-manager {

    ion-searchbar {
        padding: 0;
        height: 38px;
        margin-bottom: 12px;

        button {
            display: none;
        }
    }

    .suggested-note {
        background-color: $green-2;
        margin-bottom: 10px;
        color: white;
        line-height: 1.4em;
        padding: 12px;
        border-radius: 12px;
    }

    .searchbar-clear-button {
        display: none !important;
    }

    .sectors {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        max-height: 360px;
        overflow: auto;

        &>div {
            display: inline-flex;
            flex-wrap: wrap;
            color: #333;
            border: 1px solid #333;
            padding: 4px 4px;
            margin: 0 10px 8px 0;
            border-radius: 10px;

            .head {
                align-items: center;
                display: inline-flex;
                position: relative;
                flex-basis: 100%;

                .options {
                    position: absolute;
                    right: -2px;
                    top: -2px;
                    display: flex;

                    &>div {
                        display: inline-flex;
                        align-items: center;
                        font-size: 1em;
                        background-color: white;
                        border-radius: 10px;
                    }

                    .added-to-profile {
                        color: $green;
                        padding: 0 10px;
        
                        ion-icon {
                            margin-right: 8px;
                        }

                        &:last-child {
                            margin-left: 8px;
                        }
                    }

                    .remove-from-profile {
                        font-size: 1em;
                        color: $red;
                        border-radius: 10px;
                        padding: 6px;
                        margin-left: 8px;
                    }
                }
    
                span {
                    font-size: 22px;
                    margin-right: 6px;
                }
    
                h3 {
                    font-weight: 500;
                    font-size: 0.86em;
                    margin: 0;
                    flex-basis: 100%;
                    line-height: 1em;
                    word-break: break-word;
                }
            }

            .sub-sectors {
                flex-basis: 100%;
                overflow: auto;
                padding: 12px 0 0 0;

                .suggestions {
                    white-space: nowrap;
                    margin: 8px 0 4px 0;
                    overflow: scroll;
            
                    &>div {
                        display: inline-block;
                        background-color: $light-blue;
                        color: white;
                        padding: 4px 10px;
                        border-radius: 6px;
                        font-size: 0.86em;
                        margin-right: 8px;
                    }
            
                }
            
                .ReactTags__tags {
            
                    .ReactTags__selected {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column-reverse;
            
                        .ReactTags__tag {
                            color: white;
                            border-bottom: 1px solid #d2d2d2;
                            padding: 4px 28px 4px 8px;
                            overflow: hidden;
                            position: relative;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            display: block;
            
                            button {
                                color: white;
                                font-size: 2em;
                                position: absolute;
                                top: 1px;
                                right: 3px;
                                background-color: transparent;
                            }
            
                        }
            
                        .ReactTags__tagInput {
                            margin: 8px 0;
            
                            input {
                                background: white;
                                color: #333;
                                border: none;
                                padding: 6px 12px;
                                border-radius: 6px;
                                width: calc(100% - 24px);
                            }
            
                        }   
            
                    }
            
                }
            }

            &[data-selected="true"] {
                padding: 10px 10px;
                background-color: $light-blue-2;
                border: transparent;
                width: calc(100% - 20px);
                margin-right: 0;
                color: white;
            }
        }
    }

    &[data-section="onboarding-sectors"] {
        
    }
}