@import '../../Colors.scss';

.file-list {
    border-radius: 8px;
    overflow: hidden;
    background-color: whitesmoke;
}

.file {
    display: flex;
    overflow: hidden;
    position: relative;
    white-space: break-spaces !important;
    align-items: center;
    cursor: pointer;

    .owner {
        display: flex;

        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            border-radius: 100%;
        }
    }

    .preview {
        flex-basis: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: $light-blue-2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 4px;

        div {
            flex-wrap: wrap;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            label, ion-icon {
                color: white;
            }

            label {
                line-height: 1;
                font-size: 11px;
                margin-top: 2px
            }

            ion-icon {
                flex-basis: 100%;
                font-size: 14px;
            }
        }
    }

    .meta {
        flex-basis: calc(100% - 36px);
        padding: 8px 10px;
        align-items: center;
        display: inline-flex;
        flex-wrap: wrap;

        h2, h3, p {
            margin: 0 0 8px 0; 
            font-size: 1em;
            line-height: 1em;
        }

        h3 {
            display: flex;
            align-items: center;
        }

        h2 {
            font-weight: 700;
            max-height: 52px;
            overflow: hidden;
            flex-basis: 100%;
            padding: 0;

            span {
                padding: 3px 5px;
                margin-left: 6px;
                font-size: 0.9em;
                display: inline-block;
                border-radius: 6px;
                background-color: gainsboro;
                text-transform: capitalize;

                &.warning {
                    background-color: $amber;
                }
            }

            .description {
                padding-left: 4px;
                font-size: 0.8em;
                font-weight: 300;
                display: inline;
            }
        }

        &>*:last-child {
            margin-bottom: 0;
        }

        label {
            display: inline-flex;
            align-items: center;
            background-color: gainsboro;
            padding: 4px 4px;
            border-radius: 5px;
            line-height: 0;

            ion-icon {
                padding-right: 4px;
            }
        }
    }

    &:not(:last-of-type) {

        .meta {
            border-bottom: 1px solid gainsboro;
        }
    }

    .options {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(0, -50%);
        background-color: whitesmoke;
        padding: 8px;
        border-radius: 8px;

        ion-icon {
            font-size: 28px;
            display: block;

            &:first-child {
                margin-right: 8px;
            }
        }
    }

    &.folder {

        .preview {
            background-color: white;

            ion-icon {
                font-size: 32px;
                color: $light-blue-2;
            }
        }
    }
}