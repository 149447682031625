@import "./Colors.scss";

.ion-page[data-page="push-notification-management"] {

    ion-toolbar {
        height: 0;
        overflow: hidden;
    }

    ion-header:after {
        display: none;
    }

    #push-notification-management {
        --background: linear-gradient(124deg, rgba(53,115,230,1) 0%, rgba(19,66,154,1) 100%);
    }

    @media (max-width: 338px) {

        button, .stores a {
            margin-right: 0 !important;
            width: 100%;
        }

    }

}

.push-notification-management-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &>div {
        text-align: center;
        padding: 0 20px;

        .notification-banner {
            padding: 10px;
            background-color: white;
            color: #333;
            box-shadow: $default-box-shadow-2;
            border-radius: 10px;
            margin-bottom: 40px;
            position: relative;

            .arrow-wrapper {
                top: -72px;
                left: 24px;

                span {
                    background-color: white;
                    color: $light-blue-2
                }
            }

            .header {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-bottom: 8px;

                img {
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                    border-radius: 4px;
                }

                p {
                    font-size: 1.1em;
                    line-height: 1.1em;
                    margin: 0;
                    font-weight: 400;
                }
            }

            .body {
                text-align: left;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 10px;
                    margin-right: 10px;
                    display: block;
                }

                .meta {

                    label {
                        margin: 0;
                        font-weight: 700;
                        font-weight: 700;
                        font-size: 1em;
                        line-height: 0.8em;
                    }

                    p {
                        margin: 0;
                        line-height: 1.3em;
                        font-size: 1em;
                    }
                }
            }
        }

        img {
            max-width: 260px;
        }

        h1 {
            font-size: 1.8em;
        }

        &>h1, &>p {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            color: white;
        }

        .stores {
            margin-top: 36px;

            a {
                overflow: hidden;
                border-radius: 12px;
                display: inline-block;

                &:first-child {
                    margin-right: 12px;
                }

                img {
                    height: 52px;
                    border-radius: 12px;
                    border: 3px solid #ededed;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                }

            }

        }

        button {
            background: none;
            font-weight: 500;
            font-size: 1.1em;
            padding: 16px 24px;
            border-radius: 8px;
            margin-right: 12px;
            margin-bottom: 12px;
            min-width: 140px;

            &:last-child {
                margin-right: 0;
            }

        }

        .skip-btn {
            // background: whitesmoke;
            color: white;
            background-color: transparent;
            font-weight: 300;
        }

        .main {
            background: white;
            color: $light-blue-2;
            border-radius: 52px;;
            width: auto;
            display: block;
            margin: 40px auto 10px auto;
            font-weight: 700;
        }

    }

}