@import "../../Colors.scss";

.ion-page[data-page="profile-settings"] {
    padding-bottom: 20px;

    .info-label {
        display: block;
        margin: 0 20px;
    }

    .profile-photo {
        text-align: center;
        padding: 15px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            background-color: whitesmoke;
        }

        button {
            display: block;
            margin: auto;
            padding: 10px 16px;
            border-radius: 6px;
            margin-top: 6px;
        }

    }

    .slider-wrapper {
        padding: 0 20px 0 60px;

        .MuiSlider-valueLabel {
            display: none;
        }

        label {

        }

    }

    .sectors-slider {
        overflow: auto;
        padding-left: 20px;
        margin-top: 8px;

        &>div {

            .sector {
                border: 1px solid #444;
                color: #444;
                width: 86px;
                flex-basis: 86px;
                height: 86px;
                border-radius: 8px;
                float: left;
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                margin-right: 12px;

                &>div {
                    text-align: center;

                    ion-icon {
                        font-size: 2.3em;
                        flex-basis: 100%;
                    }

                    label {
                        display: block;
                        font-size: 0.86em;
                        line-height: 0.86em;
                        flex-basis: 100%;;
                    }

                }

                &[data-selected="true"] {
                    background-color: #444;
                    color: white;
                }

            }

        }

    }

}