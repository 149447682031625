.ion-page[data-page="help-guide"] {
    
    ion-content {
        --background: whitesmoke;

        &>div {
            padding: 15px !important;

            h1 {
                text-align: center;
                margin: 10px 0 20px 0;
                font-size: 1.4em;
            }
    
            p {
                margin: 0 0 15px 0;
            }
            
            img {
                border-radius: 6px;
                width: 100%;
                display: block;
                margin-bottom: 15px;
            }
        
            ion-textarea {
                --background: white;
                border-radius: 6px;
                --padding-start: 10px;
                --padding-end: 10px;
            }

            &>div {

                ion-button {
                    width: calc(50% - 8px);
                }

            }

        }

    }

}