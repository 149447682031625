@import '../Colors.scss';

.bottom-fab-options {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    border-radius: 12px;
    box-shadow: $default-box-shadow;
    background-color: #e7e7e7;
    width: calc(100% - 20px);
    z-index: 5;

    .left-btn {
        border-radius: 10px 0 0 10px;
    }

    .right-btn {
        border-radius: 0 10px 10px 0;
    }

    #clear-selection-btn {
        position: absolute;
        top: 0;
        color: $red;
        font-size: 1.6em;
        padding: 11px;
        right: 48px;
        background: white;
        border-radius: 12px;
    }

    button {
        display: block;
        padding: 0;
        background-color: #e7e7e7;
        position: relative;

        .badge {
            position: absolute;
            top: 2px;
            right: 2px;
            padding: 4px;
            border-radius: 100%;
            background-color: $red;
            color: white;
            width: 14px;
            height: 14px;
            animation: shake 0.7s;
            animation-timing-function: linear;
            animation-iteration-count: 3;
        }

        @keyframes shake {
            0% { transform: translate(0px, 0px); }
            50% { transform: translate(-20px, 0px); }
            100% { transform: translate(0px, 0px); }
          }

        &.add-event-btn {
            border-radius: 12px;
            flex-basis: calc(100% - 96px);
            width: calc(100% - 96px);
            text-align: left;
            padding: 0 15px;
            font-weight: 600;
            background-color: $light-blue;
            color: white;
            text-align: center;
            font-size: 0.96em;

            @media (max-width: 359px) {
                text-align: left;
            }

        }

        &.recents-btn, &.favorites-btn {
            width: 48px;
            height: 48px;
            flex-basis: 48px;
            color: #444;

            ion-icon {
                font-size: 1.7em;
                position: relative;
                top: 2px;
            }

            &.recents-btn ion-icon {
                font-size: 1.9em;
            }

        }

        &.favorites-btn {
            padding-right: 4px;
        }

    }

}

#contacts-wrapper, #chats-wrapper, #notifications-wrapper, #time-presets-wrapper, #referrals-list-wrapper {

    .bottom-fab-options {
        background: transparent;
        box-shadow: none;
        justify-content: center;
        z-index: 50;
        // bottom: 20px;

        .add-btn {
            border-radius: 12px;
            font-weight: 600;
            background-color: #509ffe;
            color: white;
            text-align: center;
            box-shadow: $default-box-shadow;
            padding: 14px 20px;
            font-size: 0.96em;
            display: inline-flex;
            align-items: center;

            ion-icon {
                margin-right: 8px;
                font-size: 1.4em;
            }

            &.delete-btn {
                background-color: $red;
            }
        }

    }

}