@import './Colors.scss';

.ion-page[data-page="notifications"] {

    ion-header {

        ion-title {
            padding-inline: 40px;
        }
    }

}

.quick-past-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 8px 0 0 0;

    div {

        span {
            background-color: whitesmoke;
            display: inline-block;
            padding: 2px 6px;
            color: #333;
            border-radius: 4px;
            font-size: 0.86em;
    
            &[data-selected="true"] {
                background-color: $light-blue-2;
                color: white;
            }

            &:not(:last-child) {
                margin-right: 8px;
            }
        }        
    }

}

.notification-types {
    margin: 10px 20px 0 20px;

    span {
        padding: 2px 8px;
        border-radius: 52px;
        font-size: 0.86em;
        margin: 0 8px 8px 0;
        display: inline-block;
    }
}

#notifications-wrapper {

    .notifications-list {
        height: 100%;
        overflow: auto;

        .group {

            &>h2 {
                font-weight: 500;
                font-size: 1.1em;
                margin-top: 0;
                padding: 0 15px;
                margin-top: 15px;
            }

            &:last-child {
                margin-bottom: 80px;
            }

        }

        .no-new-notifications {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            &>div {
                text-align: center;
                position: relative;
                top: -24px;

                ion-icon {
                    font-size: 2.5em;
                }

                h2 {
                    font-size: 1.4em;
                    margin-top: 8px;
                }

            }

        }

    }

}   