@import './Colors.scss';

.ion-page[data-page="verify-email"] {

    ion-toolbar {
        height: 0;
        overflow: hidden;
    }

    #verify-email-wrapper {

        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            &>div {
                text-align: center;
                padding: 0 20px;

                img {
                    max-width: 260px;
                }

                h1 {
                    font-size: 1.8em;
                }

                h1, p {
                    max-width: 300px;

                    span {
                        font-weight: 700;
                        display: inline-block;
                    }
                }

            }

        }
        
        button {
            background: none;
            font-weight: 500;
            font-size: 1.1em;
            padding: 16px 24px;
            border-radius: 8px;
            min-width: 140px;
            background-color: $light-blue;
            color: white;

            &.secondary-btn {
                display: block;
                margin: 20px auto 0 auto;
                background-color: whitesmoke;
                color: #555;
            }

        }
    
    }

}