@import './Colors.scss';

#timeline-clearup-wrapper {
    
    button {
        background-color: $red;
        padding: 12px;
        width: 100%;
        border-radius: 8px;
    }
}