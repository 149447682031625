@import './Colors.scss';

.skill-manager-enhanced {

    .count-down {
        color: #888;
        font-weight: 300;
    }

    .suggestions-help-text, .min-query-chars-warning {
        text-align: left;
        padding: 10px 10px 5px 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: white;
    }

    .min-query-chars-warning {
        background-color: $amber;
        display: inline-flex;
        width: 280px;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 10px;

        ion-icon {
            width: 32px;
            font-size: 24px;
        }

        span {
            width: calc(100% - 32px);
            padding-left: 10px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .skill-list-wrapper {
        width: 100%;

        .MuiCircularProgress-indeterminate {
            color: white;
            margin-bottom: 10px;
            width: 32px !important;
            height: 32px !important;
        }
    }

    .guide-wrapper {
        position: relative;
        max-width: 340px;
        width: calc(100% - 20px);
        margin: 0 auto 10px auto;

        .arrow-wrapper {
            position: absolute;
            top: -70px;
            left: 30px;
        }

        @media (min-height: 700px) {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &[data-keyboard-open="true"] {

        .guide-wrapper {
            display: none;
        }
    }

    .skill-list, .sector-suggestions, .top-skills-wrapper {
        padding: 5px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        &.top-skills-wrapper {
            position: static;
            transform: unset;
            justify-content: center;
        }

        .inline-search-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 10px 6px 10px;

            .MuiCircularProgress-indeterminate {
                color: white;
                width: 24px !important;
                height: 24px !important;
            }
        }

        &:not(.new) {
            max-height: 156px;
            overflow: auto;
        }

        .skill-item, .sector-suggestion {
            background-color: $dark-blue-2;
            color: white;
            margin: 4px;
            font-size: 14px;
            line-height: 14px;
            padding: 6px 6px;
            border-radius: 5px;
            display: inline-block;
            font-weight: 500;
            max-width: calc(100vw - 40px);
            overflow: hidden;

            span {
                display: inline-block;
            }

            &.new {
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                color: black;
                
                &>div {

                    &:first-child {
                        text-align: left;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 14px;

                        span {
                            display: block;

                            &:first-child {

                            }

                            &:last-child {
                                
                            }
                        }
                    }

                    &:last-child {
                        margin-left: 10px;
                        
                        span {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        &.sector-suggestions {
            max-height: 84px;
        }

        &.new {
            justify-content: center;
            padding-bottom: 0;

            .skill-item {
                padding: 10px;
            }
        }
    }

    .sector-selector-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;

        .content {
            background-color: white;
            color: black;
            border-radius: 20px;
            max-width: calc(100% - 20px);
            width: 400px;
            overflow: hidden;
            position: relative;

            .loading-backdrop {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 5;
                display: flex;
                align-items: center;
                justify-content: center;

                .MuiCircularProgress-indeterminate {
                    color: white;
                }
            }

            h2 {
                font-weight: 400;
                font-size: 20px;
                padding: 10px;
                margin: 0;
                background-color: $light-blue-2;
                color: white;

                span {
                    font-weight: 700;
                    display: block;
                    font-weight: 22px;
                }
            }

            .manual-sector-selector {

                .sector-inputs {

                    ion-input {
                        text-align: left;
                        border-bottom: 1px solid gainsboro;
                        --padding-start: 10px;

                        &:first-child {
                        }
                    }
                }

                .sector-selector-actions {
                    display: flex;

                    .sector-selector-action {
                        flex-basis: 50%;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px 0;

                        &:first-child {
                            border-right: 1px solid gainsboro;
                        }

                        &.main {
                            background-color: $light-blue-2;
                            color: white;
                        }
                    }
                }
            }

            .suggestion-or, .sector-or {
                padding: 2px 8px;
                background-color: #ededed;
                margin-top: -1px;
                margin-bottom: -1px;
                z-index: 2;
                position: relative;

                span {
                    font-style: italic;
                }
            }

            .sector-or {
                text-align: left;
                font-size: 18px;
                padding: 6px 8px 6px 18px;
                font-weight: 700;
            }

            .count-down {
                font-size: 40px;;
                position: absolute;
                bottom: 32px;
                right: 20px;
            }

            .suggestions {
                padding: 0 0 10px 10px;
                opacity: 1;
                transition: 0.3s all;

                &[data-obscured="true"] {
                    opacity: 0.3;
                }

                &.suggestions-list {
                    padding-bottom: 0px;
                    max-height: calc(100vh - 380px);
                    overflow: scroll;

                    .suggestion {
                        padding-left: 40px;
                        position: relative;

                        &:last-child {

                            .level-indicator-line {
                                height: 50% !important;
                            }
                        }
                    }

                    i:not(.level-indicator) {

                    }

                    .level-indicator-line {
                        height: 100%;
                        width: 4px;
                        background-color: #333;
                        left: 10px;
                        top: 0;
                        position: absolute;
                    }

                    i.level-indicator {
                        position: absolute;
                        top: 11px;
                        left: 10px;
                    }
                }

                &:not(.suggestions-list) {
                    
                    .suggestion {

                        &:first-child {
                            border-top: 1px solid gainsboro;
                        }

                        &:last-child {
                            border-bottom: unset;
                            color: #ff7b7a;
                        }
                    }
                }

                &>.suggestion {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    font-weight: 500;       
                    padding: 8px 6px;
                    flex-wrap: wrap;
                    border-bottom: 1px solid gainsboro;

                    i {
                        font-size: 16px;
                        margin-right: 4px;
                        display: inline-block;
                    }

                    ion-icon {
                        font-size: 20px;
                        margin-right: 4px;
                        display: inline-block;
                    }

                    span {
                        line-height: 16px;
                        font-size: 16px;

                        &.without {
                            background-color: #ff7b7a;
                            color: white;
                            padding: 4px 6px;
                            border-radius: 4px;
                            margin-left: 8px;
                        }
                    }

                    .warning-banner {
                        border-radius: 12px;
                        margin: 5px 0 0 24px;
                        text-align: left;
                        padding: 6px;
                        z-index: 2;

                        p {
                            margin: 0 0 0 6px;
                            
                            span {
                                font-size: 14px;
                                line-height: 16px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &[data-first-skill-selector="true"] {

        .search-input-wrapper {

            ion-icon {
                position: absolute;
                top: 13px;
                left: 10px;
                z-index: 2;
                color: black;
                font-size: 32px;
            }

            ion-input {
                --padding-start: 44px;
            }
        }
    }

    .sector-subsector-list-wrapper {

        h2 {

            label {
                font-size: 15px;
                display: block;
                font-weight: 300;
                margin-left: 0px;
            }
        }
    }

    &[data-first-skill-selector="false"] {
        align-self: center !important;

        &[data-keyboard-open="true"] {
            align-self: flex-start !important;
        }

        .center-list {
            display: block;
            padding: 0 0 0 15px;
            text-align: left;

            h2 {
                font-weight: 700;
                font-size: 22px;
                line-height: 22px;
                margin-top: 15px;
            }

            .loading-backdrop {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 5;
                display: flex;
                align-items: center;
                justify-content: center;

                .MuiCircularProgress-indeterminate {
                    color: white;
                }
            }

            .sector-subsector-list-wrapper {
                position: relative;

                h2 {
                    color: white;
                }
            }

            .skills {
                display: flex;
                flex-wrap: wrap;

                .search-input-wrapper {

                    &[data-inline="true"] {
                        display: inline-block;
                        padding: 0 !important;
                        margin-right: 8px;
                        position: relative;

                        ion-input {
                            --background: white;
                            color: black;
                            --border-radius: 6px;
                            --padding-bottom: 7px;
                            --padding-top: 7px;
                            --padding-start: 10px;
                            --padding-end: 32px;
                            min-height: unset;
                        }

                        ion-icon {
                            position: absolute;
                            top: 4.5px;
                            right: 4.5px;
                            z-index: 3;
                            color: #333;
                            font-size: 24px;
                        }
                    }
                }
                
                .search-input-wrapper {
                    width: 100%;
                    margin-right: 15px !important;
                }

                .skill-list {
                    padding: 0;
                    margin: 0 0 5px -5px;
                }

                label.guide {
                    font-size: 15px;
                    display: block;
                    font-weight: 300;
                    margin-left: 0px;
                    color: white;
                }

                .search-input-wrapper {
                    margin: 0 8px 8px 0;
                }

                .skill {
                    background-color: white;
                    color: black;
                    margin: 0 8px 8px 0;
                    border-radius: 6px;
                    padding: 4px 6px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    // float: left;

                    span {
                        font-size: 15px;
                        line-height: 15px;
                    }

                    ion-icon {
                        margin-left: 5px;
                        font-size: 20px;
                        line-height: 15px;
                    }

                    &.skill-new {
                        height: 28px;
                        padding: 0 6px 0 2px;
                        background-color: $green-2;
                        color: white;

                        ion-icon {
                            margin: unset;
                        }
                    }
                }
            }
        }
    }

    &:not([data-onboarding="true"]) {
        
        .center {
            height: unset;
        }

        .skills {

            label {
                color: black !important;
            }

            .inline-search-indicator .MuiCircularProgress-indeterminate {
                color: black !important;
            }
        }

        .sector-selector-wrapper {
            position: fixed;
            height: 100vh;
            align-items: flex-end;
            
            .content {
                margin-bottom: 24px;

                h2, .suggestion-or {
                    text-align: center;
                }
            }
        }

        .center-list {
            padding-left: 0 !important;

            .sector-subsector-list-wrapper {

                h2 {
                    color: black;
                }

                &:first-child {

                    h2 {
                        margin-top: 0;
                    }
                }
            }
        }

        .bottom-options {
            position: relative;
            margin-top: 10px;

            .search-icon {
                display: none;
            }

            ion-input {
                --background: #e6e6e6;
                border-radius: 52px;
                --padding-start: 10px;
            }
        }

        .skill-list-wrapper {

            .suggestions-help-text {
                padding: 5px;
            }
            
            .skill-list {
                padding: 0;
            }
        }
    }
}