@import './Colors.scss';

.full-screen-loader {

    button {
        background: none;
        font-weight: 500;
        font-size: 1.1em;
        padding: 16px 24px;
        border-radius: 8px;
        min-width: 140px;
        background-color: $light-blue;
        color: white;
        margin-top: 20px;
    }

}