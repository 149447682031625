@import '../Colors.scss';

.section-content-2 {
    padding: 10px 20px 0px 20px;

    .suggestions {
        white-space: nowrap;
        margin-bottom: 16px;
        overflow: scroll;
        overflow-y: hidden;

        &>div {
            display: inline-block;
            background-color: $light-blue;
            color: white;
            padding: 4px 10px;
            border-radius: 6px;
            font-size: 0.86em;
            margin-right: 8px;
        }

    }

    .ReactTags__tags {

        .ReactTags__selected {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;

            .ReactTags__tag {
                color: #333;
                border-bottom: 1px solid #d2d2d2;
                padding: 6px 28px 6px 12px;
                overflow: hidden;
                position: relative;
                margin-right: 8px;
                margin-bottom: 8px;
                display: block;

                button {
                    color: #333;
                    font-size: 2em;
                    position: absolute;
                    top: 1px;
                    right: 3px;
                    background-color: transparent;
                }
            }

            .ReactTags__tagInput {
                margin-bottom: 8px;

                input {
                    background: #e6e6e6;
                    border: none;
                    padding: 8px 20px;
                    border-radius: 6px;
                    width: calc(100% - 40px);
                }

            }   

        }

    }
    
}