@import './Colors.scss';

#calendar-wrapper {
    --offset-bottom: 0 !important;
    background: white;
}

.ion-page[data-page="calendar"] {

    ion-toolbar {
        height: 0;
        overflow: hidden;
        --background: #3573E6; // $light-blue-2
    }

}

.calendar-wrapper {
    position: relative;

    #resize-calendar-btn {
        position: absolute;
        bottom: -15px;
        right: 8px;
        background-color: $dark-blue;
        border-radius: 4px;
        padding: 4px;
        display: inline-block;
        width: 32px;
        height: 32px;
        z-index: 2;
        transition: all 0.2s;

        svg {
            width: 8px;
            color: white;
        }

    }

    .logo, .env {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 40px;

        &.env {
            background: red;
            text-align: center;
            padding: 3px;
            font-weight: 700;
            color: white;
            border-radius: 12px;
        }
    }

    #jump-to-today-btn, #sync-btn {
        position: absolute;
        top: 16px;
        font-size: 24px;
        right: 16px;
        color: white;
        background: none;
        border: none;
        padding: 0;
        transition: all 0.4s;

        @keyframes rotating {
            from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        &#jump-to-today-btn>span {
            position: absolute;
            color: $light-blue-2;
            left: 50%;
            top: 11px;
            font-weight: 600;
            transform: translate(-50%, 0);
            font-size: 0.4em;
        }

        &#sync-btn {
            width: 27px;
            overflow: hidden;
            right: 50px;
            top: 16px;

            .rotate {
                animation: rotating 2s linear infinite;
            }
            
            span {
                overflow: hidden;
                text-overflow: hidden;
                height: 20px !important;
                margin: 0;
                width: 0;
                font-size: 20px !important;
                color: white;
            }
        }

        &#sync-btn[data-expanded="true"] {
            background: $green;
            color: white;
            padding: 8px 12px;
            display: inline-flex;
            font-size: 20px;
            top: 11px;
            border-radius: 13px;
            z-index: 100;
            right: 15px;

            span {
                margin-right: 8px;
            }

            ion-icon {
                color: white !important;
            }

            &[data-text-status="done"] {
                background: $green;
                width: 245px;

                span {
                    width: 200px;
                }
            }

            &[data-text-status="syncing"] {
                background: $amber;
                width: 140px;

                span {
                    width: 95px;
                }
            }
        }
    }

    .react-calendar {
        width: 100%;
        background-color: $light-blue-2;
        border: none;

        .react-calendar__navigation {
            align-items: center;
            justify-content: center;
            padding-top: 18px;

            .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
                display: none;
            }
    
            .react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
                color: white;
                font-size: 1.6em;
                background-color: transparent !important;
            }
    
            .react-calendar__navigation__label {
                flex-grow: unset !important;
                background: none;
                font-weight: 700;
                margin-top: -4px;
                font-size: 0.9em;
                padding-left: 0;
                padding-right: 0;
            }
    
            .react-calendar__navigation__label__labelText {
                color: white;
            }

        }

        .react-calendar__viewContainer {
            padding-bottom: 8px;

            .react-calendar__year-view__months .react-calendar__tile--now, .react-calendar__year-view__months .react-calendar__tile--hasActive,
            .react-calendar__decade-view .react-calendar__tile--now, .react-calendar__decade-view .react-calendar__tile--hasActive,
            .react-calendar__century-view .react-calendar__tile--now, .react-calendar__century-view .react-calendar__tile--hasActive
            {
                background: transparent;
            }

            &>div {
                max-width: 320px;
                margin: auto;
            }

            .react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view {

                .react-calendar__year-view__months__month, .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
                    color: white;
                }

            }   
        
            .react-calendar__month-view {

                .react-calendar__month-view__weekdays__weekday {
                    padding: 0;
                }

                .react-calendar__month-view__weekdays__weekday abbr {
                    color: white;
                    font-weight: 300;
                    text-decoration: none;
                }
    
                .react-calendar__tile {
                    height: 40px;
                    margin-bottom: 6px;
                    position: relative;
    
                    &.react-calendar__month-view__days__day {
                        color: white;
    
                        abbr {
                            position: relative;
                            z-index: 10;
                            font-size: 1.16em;
                            font-weight: 500;
                            left: -0.5px;
                            top: -0.5px;
                        }
    
                        // Not showing the single selected date for react-date component, we only use this for positioning
                        &.react-calendar__tile--active {
                            background-color: transparent;
                        }

                        .selected-on-timeline {
                            background: transparentize($color: $dark-blue, $amount: 0.3) !important;
                        }

                        .selected {
                            background-color: transparentize($color: $light-blue, $amount: 0.01) !important;
                        }
    
                        // Reset today's stile
                        &.react-calendar__tile--now {
                            background-color: transparent;

                            .today {
                                background-color: transparentize($color: $dark-blue, $amount: 0.7);
                            }
                        
                        }
    
                        // This allows multiple selections
                        // &.selected {
                        //     background-color: rgba(0, 0, 0, 0.4);
                        // }
    
                        // Greyin out prev month
                        &.react-calendar__month-view__days__day--neighboringMonth {
                            color: #bebebe;
                        }

                        .crossed-out {
                            position: absolute;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                            left: 50%;
                            width: 2px;
                            height: 24px;
                            background-color: white;
                            opacity: 0.4;
                            display: inline-block
                        }

                        .selected, .today, .selected-on-timeline {
                            background: $light-blue;
                            // border: 2px solid darken($color: $dark-blue, $amount: 10);
                            border-radius: 100%;
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 1;
                        }

                        &.inferred {
                            
                            abbr {
                                color: white;
                                opacity: 0.4;
                            }

                        }

                        &.selected {
                            opacity: 1 !important;

                            abbr {
                                opacity: 1 !important;
                            }

                        }
    
                    }
    
                    &:hover, &:focus {
                        background: inherit;
                    }
    
                }

            }

        }

    }

    &[data-shrinked="true"] {

        .react-calendar {

            .react-calendar__navigation {
                display: flex;
                height: 56px;
                padding-top: 6px;
                margin-bottom: 0;
            }

            .react-calendar__viewContainer {
                display: none;
            }

        }

        #resize-calendar-btn {
            bottom: 12px;
            right: 12px;
        }

    }

    .collision-header {
        position: absolute;
        height: 100%;
        background: $amber;
        width: calc(100% - 30px);
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        flex-wrap: wrap;
        text-align: center;
        padding: 10px 15px;

        h2, ion-icon, p {
            color: white;
            margin: 0;
        }

        h2, p {
            flex-basis: 100%;
        }

        h2 {
            font-size: 1.4em;
        }

        p {
            font-size: 1em;
        }

        ion-icon {
            font-size: 2em;
            position: absolute;
            top: 10px;
            right: 10px;
            display: none;
        }

    }

    &[data-theme="1"] {
        
        .react-calendar {
            background-color: #389393;
        }
        
    }

    &[data-theme="2"] {
        
        .react-calendar {
            background-color: #0f3057;
        }
        
    }

    &[data-theme="3"] {
        
        .react-calendar {
            background-color: #2d4059;
        }
        
    }

    &[data-theme="5"] {
        
        .react-calendar {
            background-color: #555;
        }
        
    }

    &[data-theme="6"] {
        
        .react-calendar {
            background-color: #333;
        }
        
    }

    &[data-theme="7"] {
        
        .react-calendar {
            background-color: black;
        }
        
    }

    &[data-theme="4"] {
        border-bottom: 1px solid #ddd;

        #jump-to-today-btn {
            color: #555;

            span {
                color: white;
            }

        }   
        
        .react-calendar {
            background-color: white;
    
            .react-calendar__navigation {
        
                .react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
                    color: #333;
                }
        
                .react-calendar__navigation__label__labelText {
                    color: #333;
                }
    
            }
    
            .react-calendar__viewContainer {
    
                .react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view {
    
                    .react-calendar__year-view__months__month, .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
                        color: #333;
                    }
    
                }   
            
                .react-calendar__month-view {
    
                    .react-calendar__month-view__weekdays__weekday abbr {
                        color: #333;
                    }
        
                    .react-calendar__tile {
        
                        &.react-calendar__month-view__days__day {
                            color: #333;
        
                            // Reset today's stile
                            &.react-calendar__tile--now {
                                background-color: transparentize($color: $light-blue, $amount: 1);
                            }
    
                            // Greyin out prev month
                            &.react-calendar__month-view__days__day--neighboringMonth {
                                color: #999;
                            }
    
                            .crossed-out {
                                background-color: #999;
                            }
    
                            .selected {
                                background: $light-blue;
                            }
    
                            &.inferred {
                                
                                abbr {
                                    color: black;
                                    opacity: 0.4;
                                }
    
                            }
    
                        }
        
                    }
    
                }
    
            }
    
        }
        
    }

}   

@media (max-height: 632px) {

    .availability-circle, .today, .react-calendar__tile, .selected-on-timeline, .today {
        height: 32px !important;
        width: 32px !important;
    }

    .calendar-wrapper .react-calendar .react-calendar__navigation {
        margin-bottom: 4px;
    }

}