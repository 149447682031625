@import './Colors.scss';

.bottom-fab-options[data-height-loaded="false"] {
    visibility: hidden;
}

.error-banner, .warning-banner {
    z-index: 300;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 15px;
    line-height: 18px;
    padding: 10px;

    ion-icon {
        font-size: 20px;
    }

    p {
        margin: 0 0 0 10px;
        flex-basis: calc(100% - 28px);

        span {
            display: block;

            &:first-child {
                margin-bottom: 5px;
            }
        }
    }

    &.error-banner {
        position: fixed;
        background: $red;
    }

    &.warning-banner {
        background: $amber;
    }
}

.timeline {
    overflow: auto;
    margin: 0 10px 0px 10px;
    padding-bottom: 60px;
    position: relative;

    .inferred {
        background-color: $amber;
        padding: 12px;
        margin-bottom: 16px;
        border-radius: 12px;
        color: white;
        font-size: 0.9em;
        line-height: 1.44em;
        display: block;
    }

    .event-timeline-item-wrapper {
        display: flex;
        padding-top: 15px;

        &.empty {
            padding-top: 0;
        }

        .day-header {
            margin: 0 0 0 0;
            font-weight: 500;
            position: sticky;
            position: -webkit-sticky;
            top: 3px;
            text-align: center;
            padding-right: 12px;
            height: 50px;
            padding-top: 2px;
            width: 42px;

            &>div {
                color: #222;
            }
    
            .weekday {
                font-size: 0.9em;
                line-height: 1em;
                font-weight: 500;
            }
    
            .day {
                font-weight: 700;
                font-size: 1.5em;
                line-height: 1em;
            }
    
            .month-year {
                font-size: 0.6em;
                font-weight: 400;
                line-height: 1.3em;
            }
    
        }

        .events {
            flex-basis: calc(100% - 42px);

            .event-item:last-child, .inferred:last-child {
                margin-bottom: 0;
            }

            .last-available-date {
                background-color: $amber;
                color: white;
                padding: 8px 8px 16px 8px;
                border-radius: 8px;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: -12px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;

                ion-icon {
                    font-size: 32px;
                    flex-basis: 32px;
                }

                p {
                    flex-basis: calc(100% - 32px);
                    padding-left: 8px;
                    margin: 0;
                }
            }
        }

    }

    &[data-height-loaded="false"] {

        &>* {
            visibility: hidden;
        }

    }

}