@import './Colors.scss';

* {
    font-family: 'Mukta', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* mukta-200 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 200;
    src: local('Mukta ExtraLight'), local('Mukta-ExtraLight'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-300 - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 300;
    src: local('Mukta Light'), local('Mukta-Light'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-regular - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400;
    src: local('Mukta Regular'), local('Mukta-Regular'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-500 - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 500;
    src: local('Mukta Medium'), local('Mukta-Medium'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-600 - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 600;
    src: local('Mukta SemiBold'), local('Mukta-SemiBold'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-700 - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    src: local('Mukta Bold'), local('Mukta-Bold'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* mukta-800 - latin-ext_latin_devanagari */
  @font-face {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 800;
    src: local('Mukta ExtraBold'), local('Mukta-ExtraBold'),
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/mukta-v7-latin-ext_latin_devanagari-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

button:focus, button:hover, button:active, input, textarea {
     outline: 0 !important;
}

html.ios, html.android {

     button {
          cursor: unset !important;
     }

}

html.plt-desktop {
     
     .end-date {

          p {
               display: none;
          }

          input {
               opacity: 1 !important;
               position: static !important;
          }
      
     }

}

.clearfix::after {
     content: "";
     clear: both;
     display: table;
}

.emoji-popover {
     z-index: 4000;
     position: absolute;
}

.no-events {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-wrap: wrap;

     &>div {
         flex-basis: 100%;
         text-align: center;;

         h3 {
             font-weight: 700;
             font-size: 1.3em;
             margin: 0 0 20px 0;
         }

         ion-icon {
             font-size: 2.4em;
             margin-bottom: 12px;
         }

         button {
             padding: 16px 32px;
             border: $dark-blue 1px solid;
             background-color: transparent;
             color: $dark-blue;
             border-radius: 52px;
             font-size: 1em;
         }

     }

     &.end {
         visibility: hidden;
     }

     &.no-events {
         align-items: flex-end;

         .dotted-arrow {
             position: relative;
             width: 68px;
             left: -28px;
             height: 80px;
             margin-bottom: 20px;
         }

     }

 }

ion-alert {

     .alert-message, .alert-title {
          word-break: break-word;
     }

}

.no-results {
     display: flex;
     align-items: center;
     justify-content: center;
     height: calc(100vh - 190px);
     text-align: center;
}

ion-tabs {

     .badge {
          position: absolute;
          top: 2px;
          right: 18px;
          background: red;
          padding: 0 6px;
          color: white;
          font-size: 0.9em;
          border-radius: 100%;
     }

}

// ion-tab-button {
//      display: inline-flex;
//      flex-wrap: nowrap;
//      flex-direction: unset;

//      ion-icon {
//           font-size: 1.8em;
//      }

//      ion-label {
//           font-size: 1.2em;
//           padding-left: 12px;
//      }

// }

.duration {
     display: flex;
     align-items: center;

     .start, .end {
         border: 1px solid $light-grey;
         border-radius: 12px;
         padding: 2px 8px;

         .MuiInput-underline {
             font-size: 0.9em;
             color:$light-black;

             &:after, &:before {
                 display: none;
             }

         }

     }

     .presets {
         background-color: #E5E5E5;
         color:$light-black;
         margin-left: 10px;
         margin-left: 10px;
         padding: 12px 20px;
         border-radius: 12px;
     }

     label {
         margin: 0 12px
     }

}

.nonblocking-loading-wrapper {
     text-align: center;
     background: white;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     border-radius: 100%;
     align-items: center;
}

.ion-page[data-page="contacts"] {
     ion-title {
          margin-top: 5px;
     }

     ion-segment {
          min-height: unset;
          margin: 0 15px 5px 15px;
     }
}

.ion-page[data-page="contacts"],
.ion-page[data-page="notifications"],
.ion-page[data-page="files"],
.ion-page[data-page="chat"],
.ion-page[data-page="me"] {

     // ion-toolbar {
     //     height: 0;
     //     overflow: hidden;
     //     --background: #3573E6; // $light-blue-2
     // }
 
     .nonblocking-loading-wrapper {
         left: auto;
         right: 8px;
         transform: unset;
         top: 8px;
         align-items: center;
         font-weight: 500;
         padding-right: 20px;
         border-radius: 52px !important;
         padding: 6px 12px 6px 6px;
         background-color: $light-blue-2;
         color: white;
         box-shadow: $default-box-shadow;
         z-index: 100;
         
         &>div {
             margin-right: 12px;
             width: 24px !important;
             color: white;
             height: 24px !important;
         }
     }
 }

.guide { 
     height: calc(100% - 90px);
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;

     div {
          padding: 20px;
          text-align: center;

          img {
               max-width: 280px;
          }
     
     }

}

.keyboard-hide {
     position: fixed;
     color: white;
     padding: 12px;
     font-size: 1.2em;
     line-height: 0.9em;
     border-radius: 1;
     border-radius: 10px 10px 0 0;
     right: 20px;
     background: #333;
     bottom: 0;
     z-index: 100;

     span {
          margin-right: 8px;
     }
}

.MuiTooltip-tooltip {
     color: $red;
}

.warning {
     display: flex;
     background-color: $red;
     color: white;
     margin: 10px 20px 4px 20px;
     border-radius: 12px;
     padding: 12px;
     display: flex;
     justify-content: space-between;
     align-items: center;

     ion-icon {
          flex-basis: 40px;
          font-size: 2em;
     }

     div {
          flex-basis: calc(100% - 40px);
          padding-left: 12px;

          p {
               color: white !important;
               margin: 0;
          }

          button {
               width: 100%;
               border-radius: 4px;
               padding: 12px 20px;
               background-color: white;
               color: $red;
               margin-top: 12px;
          }
     }

}

body[data-availability-color="1"] {
     .event-item[data-type="4"] { border-left-color: $green !important; .icon path { fill: $green !important; } }
     .selected-indicator[data-type="4"] { path { fill: $green !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $green !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $green !important; } }
     .inline-select>div[data-id="4"] path { fill: $green !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $green !important; }
     .half-available, .work-type[data-type="4"] { background-color: $green; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $green; }
}

body[data-availability-color="2"] {
     .event-item[data-type="4"] { border-left-color: $yellow !important; .icon path { fill: $yellow !important; } }
     .selected-indicator[data-type="4"] { path { fill: $yellow !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $yellow !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $yellow !important; } }
     .inline-select>div[data-id="4"] path { fill: $yellow !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $yellow !important; }
     .half-available, .work-type[data-type="4"] { background-color: $yellow; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $yellow; }
}

body[data-availability-color="3"] {
     .event-item[data-type="4"] { border-left-color: $red !important; .icon path { fill: $red !important; } }
     .selected-indicator[data-type="4"] { path { fill: $red !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $red !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $red !important; } }
     .inline-select>div[data-id="4"] path { fill: $red !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $red !important; }
     .half-available, .work-type[data-type="4"] { background-color: $red; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $red; }
}

body[data-availability-color="4"] {
     .event-item[data-type="4"] { border-left-color: $amber !important; .icon path { fill: $amber !important; } }
     .selected-indicator[data-type="4"] { path { fill: $amber !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $amber !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $amber !important; } }
     .inline-select>div[data-id="4"] path { fill: $amber !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $amber !important; }
     .half-available, .work-type[data-type="4"] { background-color: $amber; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $amber; }
}

body[data-availability-color="5"] {
     .event-item[data-type="4"] { border-left-color: $light-blue !important; .icon path { fill: $light-blue !important; } }
     .selected-indicator[data-type="4"] { path { fill: $light-blue !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $light-blue !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $light-blue !important; } }
     .inline-select>div[data-id="4"] path { fill: $light-blue !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $light-blue !important; }
     .half-available, .work-type[data-type="4"] { background-color: $light-blue; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $light-blue; }
}

body[data-availability-color="6"] {
     .event-item[data-type="4"] { border-left-color: $extra-light-black !important; .icon path { fill: $extra-light-black !important; } }
     .selected-indicator[data-type="4"] { path { fill: $extra-light-black !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $extra-light-black !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $extra-light-black !important; } }
     .inline-select>div[data-id="4"] path { fill: $extra-light-black !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $extra-light-black !important; }
     .half-available, .work-type[data-type="4"] { background-color: $extra-light-black; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $extra-light-black; }
}

body[data-availability-color="7"] {
     .event-item[data-type="4"] { border-left-color: $light-black !important; .icon path { fill: $light-black !important; } }
     .selected-indicator[data-type="4"] { path { fill: $light-black !important; } }
     .event-item[data-type="3"] { .icon path:nth-child(2), .icon path:nth-child(4) { fill: $light-black !important; } }
     .selected-indicator[data-type="3"] { path:nth-child(2), path:nth-child(4) { fill: $light-black !important; } }
     .inline-select>div[data-id="4"] path { fill: $light-black !important; }
     .inline-select>div[data-id="3"] path:nth-child(4), .inline-select>div[data-id="3"] path:nth-child(2) { fill: $light-black !important; }
     .half-available, .work-type[data-type="4"] { background-color: $light-black; }
     .work-type[data-type="2"]:nth-of-type(2) { background-color: $light-black; }
}


body[data-work-color="1"] {
     .event-item[data-type="1"] { border-left-color: $green !important; .icon path { fill: $green !important; } }
     .selected-indicator[data-type="1"] { path { fill: $green !important; } }
     .inline-select>div[data-id="1"] path { fill: $green !important; }
     .work-type[data-type="1"] { background-color: $green; }
}

body[data-work-color="2"] {
     .event-item[data-type="1"] { border-left-color: $yellow !important; .icon path { fill: $yellow !important; } }
     .selected-indicator[data-type="1"] { path { fill: $yellow !important; } }
     .inline-select>div[data-id="1"] path { fill: $yellow !important; }
     .work-type[data-type="1"] { background-color: $yellow; }
}

body[data-work-color="3"] {
     .event-item[data-type="1"] { border-left-color: $red !important; .icon path { fill: $red !important; } }
     .selected-indicator[data-type="1"] { path { fill: $red !important; } }
     .inline-select>div[data-id="1"] path { fill: $red !important; }
     .work-type[data-type="1"] { background-color: $red; }
}

body[data-work-color="4"] {
     .event-item[data-type="1"] { border-left-color: $amber !important; .icon path { fill: $amber !important; } }
     .selected-indicator[data-type="1"] { path { fill: $amber !important; } }
     .inline-select>div[data-id="1"] path { fill: $amber !important; }
     .work-type[data-type="1"] { background-color: $amber; }
}

body[data-work-color="5"] {
     .event-item[data-type="1"] { border-left-color: $light-blue !important; .icon path { fill: $light-blue !important; } }
     .selected-indicator[data-type="1"] { path { fill: $light-blue !important; } }
     .inline-select>div[data-id="1"] path { fill: $light-blue !important; }
     .work-type[data-type="1"] { background-color: $light-blue; }
}

body[data-work-color="6"] {
     .event-item[data-type="1"] { border-left-color: $extra-light-black !important; .icon path { fill: $extra-light-black !important; } }
     .selected-indicator[data-type="1"] { path { fill: $extra-light-black !important; } }
     .inline-select>div[data-id="1"] path { fill: $extra-light-black !important; }
     .work-type[data-type="1"] { background-color: $extra-light-black; }
}

body[data-work-color="7"] {
     .event-item[data-type="1"], .event-item[data-type="3"]{ border-left-color: $light-black !important; .icon path { fill: $light-black !important; } }
     .selected-indicator[data-type="1"] { path { fill: $light-black !important; } }
     .inline-select>div[data-id="1"] path { fill: $light-black !important; }
     .work-type[data-type="1"] { background-color: $light-black; }
}


body[data-private-color="1"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $green !important; .icon path { fill: $green !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $green !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $green !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $green; }
}

body[data-private-color="2"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $yellow !important; .icon path { fill: $yellow !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $yellow !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $yellow !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $yellow; }
}

body[data-private-color="3"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $red !important; .icon path { fill: $red !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $red !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $red !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $red; }
}

body[data-private-color="4"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $amber !important; .icon path { fill: $amber !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $amber !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $amber !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $amber; }
}

body[data-private-color="5"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $light-blue !important; .icon path { fill: $light-blue !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $light-blue !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $light-blue !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $light-blue; }
}

body[data-private-color="6"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $extra-light-black !important; .icon path { fill: $extra-light-black !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $extra-light-black !important; }
     .selected-indicator[data-type="2"] path, .selected-indicator[data-type="3"] path { fill: $extra-light-black !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $extra-light-black; }
}

body[data-private-color="7"] {
     .event-item[data-type="2"], .event-item[data-type="3"] { border-left-color: $light-black !important; .icon path { fill: $light-black !important; } }
     .inline-select>div[data-id="2"], .inline-select>div[data-id="3"] path { fill: $light-black !important; }
     .selected-indicator[data-id="2"] path, .selected-indicator[data-id="3"] path { fill: $light-black !important; }
     .work-type[data-type="2"], .work-type[data-type="3"] { background-color: $light-black; }
}

.strike {
     text-decoration: line-through;
     color: $red !important;
}

.top-banner {
     background-color: $green;
     display: flex;
     align-items: center;
     justify-content: center;
     color: white;
     padding: 8px;
     margin: 0 !important;
     border-radius: 0 !important;

     ion-icon {
         position: relative;
         left: -4px;
         top: -1px;
         flex-basis: 52px;
         font-size: 1.4em;
     }

     span {
         display: inline-block;
         flex-basis: calc(100% - 52px);
     }

     &.warning {
         background-color: $amber;
     }

     &.info {
          background-color: $light-blue-2 !important;
     }

}

#delete-container > div:nth-child(2) {
     background-color: white;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
     max-height: calc(100% - 10px);
}

.reactour__popover {
     background: $light-blue-2 !important;
     color: white !important;
     padding: 16px 40px 16px 16px !important;

     button {
          color: white !important;
     }

     &>span, &>div {
          display: none;

          &>div, &>button:first-child {
               display: none;
          }

          button {
               display: block;
               text-align: center;
               width: 100%;
          }

          svg {
               color: white;
               width: 120px;
               height: 120px;
          }
     }
}

body.allow-tour-paging .reactour__popover {
     &>div {
          display: flex;
     }
}

// @font-face {
//      font-family: 'Material Icons';
//      font-style: normal;
//      font-weight: 400;
//      src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
//      src: local('Material Icons'),
//        local('MaterialIcons-Regular'),
//        url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
//        url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
//        url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
//    }
   
.tab-tooltip-wrapper {
     position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    bottom: 50px;

     &>div {
          border-radius: 8px;
          width: 160px;
          text-align: center;
          padding: 8px;
          position: absolute;
          bottom: 0px;
          background-color: $light-blue-2;
          text-align: center;
          box-shadow: $default-box-shadow;
          color: white;

          &.chat-tooltip {
               left: 0px;
               border-radius: 0 12px 0 0;
          }

          &.rating-tooltip {
               right: 60px;
               border-radius: 12px 12px 0 0;
          }

          &.notification-tooltip {
               right: 0px;
               border-radius: 12px 0 0 0;
          }
     }
}

.tooltip-styled {
     padding: 6px 8px;
     position: absolute;
     width: auto;
     white-space: nowrap;
     word-wrap: no-wrap;
     border-radius: 8px;
     background-color: #FD5D5D; // $light-blue-2;
     // border: 3px solid white;
     // left: 50%;
     // transform: translate(-50%);
     transform-style: preserve-3d;
     z-index: 200;
     display: block;
     color: white;
     box-shadow: 1px 1px 20px rgba(6, 65, 173, 0.3);
     top: -44px;
     right: 50%;
     transform: translate(50%, 0);
     text-align: center;
     min-width: 28px;
     font-size: 24px;
     line-height: 32px;
}

.tooltip-styled:after {
     content: "";
     position: absolute;
     display: block;
     width: 14px;
     height: 14px;
     transform-origin: 50% 50%;
     transform: rotate(45deg) translateX(-50%);
     background-color: #FD5D5D; // $light-blue-2;
     left: 50%;
     top: -1px;
     z-index: 400;
     top: auto;
     bottom: -11px;
}

.tooltip-styled:before {
     content: "";
     display: block;
     position: absolute;
     width: 14px;
     height: 14px;
     transform-origin: 50% 50%;
     transform: rotate(45deg) translateX(-50%) translateZ(-1px);
     background-color: #FD5D5D; // $light-blue-2;
     left: 50%;
     top: 0px;
     z-index: -1;
     box-shadow: -1px -1px 20px #aaa;
     top: auto;
     bottom: -11px;
}

.tooltip-styled {

}

.tooltip-styled:after {

}

.tooltip-styled:before {
 
}

.Toastify {

     &>div {
          top: env(safe-area-inset-top) !important;
     }
}

.action-sheet-sub-title, .action-sheet-title {
     height: unset !important;
}

.group.scrollable-group {
     background: #FFF;
     // padding: 16px 0 0 0;

     .head {
         background: #e9e9e9;
         border-bottom: 1px solid #e9e9e9;
         border-top: 1px solid #e9e9e9;
         color: #333;
         margin: 0;
         padding: 4px 0 4px 15px;
         position: sticky;
         top: -1px;
         text-transform: uppercase;
         font-size: 0.9em;
         z-index: 10;

         ion-icon {
               margin: 0 10px 0 5px;
               font-size: 18px;
               position: relative;
               top: 4px;
         }
     }

     .item {
         margin: 0;
         padding: 0 0 0 12px;
         white-space: nowrap;
     }

     dd + dd {
         border-top: 1px solid #CCC;
     }

     &:first-child {
         padding-top: 0;
     }

     &:last-child {
         padding-bottom: 92px;
     }

 }

.arrow-alt {
     border: solid #555;
     border-width: 0 5px 5px 0;
     display: inline-block;
     padding: 5px;

     &.right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
     }
     
     &.left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
     }
     
     &.up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
     }
     
     &.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
     }
}

button.main {
     background-color: $light-blue-2;
     color: white;
     width: 100%;
     font-size: 18px;
     font-weight: 600;
     padding: 15px;
     border-radius: 52px;
}

.highlight-animation {
     position: relative;
     transition: background-position 0.4s;
     transform: skewX(30deg);
     padding: 0.25em 0.5em;
     margin: 0 -0.55em;
     background-blend-mode: multiply;
     mix-blend-mode: multiply;
     animation: mark linear 0.7s forwards;
     background: 0 0;
     background: linear-gradient(to right, rgba(#000, 0) 50%, rgba(#2f8ac9, 0.85) 50%);
     background-position: 0;
     background-size: 200%;
}

@keyframes mark {
     to {
          background-position: -100%;
     } 
}

body.iframe {

     ion-tab-bar, .side-header {
          display: none !important;
     }

     .ion-page {
          border-left: 0 !important;
     }
}

.lds-ring {
     display: inline-block;
     position: relative;
     width: 26px;
     height: 26px;
   }
   .lds-ring div {
     box-sizing: border-box;
     display: block;
     position: absolute;
     width: 26px;
     height: 26px;
     border: 3px solid $light-blue-2;
     border-radius: 50%;
     animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
     border-color: $light-blue-2 transparent transparent transparent;
   }
   .lds-ring div:nth-child(1) {
     animation-delay: -0.45s;
   }
   .lds-ring div:nth-child(2) {
     animation-delay: -0.3s;
   }
   .lds-ring div:nth-child(3) {
     animation-delay: -0.15s;
   }
   @keyframes lds-ring {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
.MuiTooltip-popper {
     width: 240px;

     &>div {
          margin: 0;
     }
}

.info-box {
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: $light-blue-2;
     color: white;
     border-radius: 10px;
     padding: 12px;

     ion-icon {
          font-size: 28px;
          margin-right: 10px;
          flex-basis: 36px;
     }

     p {
          margin: 0;
          flex-basis: calc(100% - 36px);
          font-size: 16px;
          line-height: 20px;
     }
}

.split-inputs {
     display: flex;
}

*[data-report-blurred="true"] {
     filter: blur(3px);
     position: relative;
     z-index: 1;
}