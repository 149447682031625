@import './Colors.scss';

.arrow-wrapper {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 2;

    svg {
        height: 60px;
        fill: white;
        transform: rotate(20deg);
    }

    span {
        position: relative;
        top: -8px;
        right: -10px;
        color: white;
        background-color: $light-blue;
        border-radius: 12px;
        padding: 2px 8px;
    }
}

.success-message {
    position: absolute;
    max-width: 300px;
    border-radius: 16px;
    width: 100%;
    padding: 20px;
    background-color: $green-2;
    color: white;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: calc(100% - 80px);
    box-shadow: $default-box-shadow;
    border: 5px solid white;

    i {
        display: block;
        text-align: center;
        font-size: 52px;
        animation: thumbsUpLike 0.8s ease-in-out;
    }

    p {
        margin: 10px 0 0 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
    }
}

@keyframes thumbsUpLike {
    0% {
      transform: rotate(0deg) scale(1) translateY(0);
    }
    50% {
      transform: rotate(-20deg) scale(1.05) translateY(-10px);
    }
    100% {
      transform: rotate(0deg) scale(1) translateY(0);
    }
}

.ion-page[data-page='onboarding'] {

    ion-header, ion-footer {
        height: 0;
        overflow: hidden;
    }

    ion-content {

        .swiper-wrapper-outer {

            .swiper-pagination {
                display: block;
                bottom: 78px;

                .swiper-pagination-bullet-active {
                    background: white;
                }
            }

            .swiper-slide {

                .slide-wrapper {

                    section {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: center;
                        height: 100vh;

                        .showcase {
                            align-self: flex-end;
                            margin: 15px;
                            max-width: 400px;
                            width: calc(100% - 30px);

                            .preview-img {
                                border-radius: 40px;
                                border: 10px solid gainsboro; //  rgba(0,0,0,0.3);
                                overflow: hidden;
                                padding-top: 28px;
                                position: relative;
    
                                .notch {
                                    position: absolute;
                                    top: 14px;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                    width: 52px;
                                    height: 10px;
                                    background-color: gainsboro;
                                    border-radius: 8px;
                                }
    
                                img {
                                    display: block;
                                }
                            }

                            h2 {
                                color: white;
                                font-size: 24px;
                                font-weight: 700;
                                margin: 28px 0 10px 0;
                            }
                        }

                        button {
                            align-self: flex-end;
                            margin: 20px;
                            font-weight: 700;
                            border-radius: 52px;
                            padding: 16px 48px;
                            background-color: white;
                            color: black;
                            font-size: 1em;
                            position: relative;
                            max-width: 400px;
                            width: calc(100% - 30px);
                        }
                    }
                }
            }
        }

        main {
            background-color: $light-blue-2;
            min-height: 100%;
            display: flex;
            flex-wrap: wrap;

            h1, h2, h3, p, .step {
                color: white;
            }

            &>div {
                flex-basis: 100%;
                height: fit-content;

                &.hero {
                    text-align: center;
                    align-self: flex-start;
                    padding-bottom: 28px;
                    padding-top: 0;
                    overflow: hidden;

                    h1 {
                        font-size: 1.8em;
                        line-height: 1.8em;
                        font-weight: 700;
                        margin: 0;
                        display: none;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 48px;
                            height: 48px;
                            margin-right: 4px;
                            display: inline-block;
                        }

                        span {
                            display: inline-block;
                        }
                    }

                    h2 {
                        font-weight: 700;
                        font-size: 1.26em;
                        line-height: 1.6em;
                        max-width: 360px;
                        margin: 10px auto 10px auto;
                        padding: 0 10px;
                    }

                    &>img {
                        position: relative;
                        width: calc(100% + 40px);
                        max-width: calc(100% + 40px);
                        left: -20px;
                        position: relative;
                        display: block;
                        margin: auto;
                    }

                    button {
                        font-weight: 700;
                        border-radius: 52px;
                        padding: 16px 48px;
                        background-color: white;
                        color: black;
                        font-size: 1em;
                        margin-top: 12px;
                        width: 320px;
                        position: relative;
                        max-width: 100%;

                        span {
                            position: absolute;
                            left: 50%;
                            top: 17px;
                            transform: translate(-50%, 0);
                        }
                    }

                    .downloads {
                        max-width: 320px;
                        margin: auto;

                        .or {
                            position: relative;
                            height: 64px;

                            hr {
                                width: 160px;
                                margin: auto;
                                position: relative;
                                top: 50%;
                                transform: translate(0, -50%);
                                background: white;
                            }

                            label {
                                background-color: $light-blue-2;
                                position: absolute;
                                color: white;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                padding: 4px 16px
                            }

                        }

                        img {
                            display: block;
                            border-radius: 12px;
                            overflow: hidden;
                            margin: auto;
                            width: 226px;
                            left: unset;
                            
                            &:first-of-type {
                                margin-bottom: 16px
                            }

                        }

                    }

                    .bottom {
                        padding: 0 0 15px 0;
                    }

                    &[data-native-app="true"] {

                        .bottom {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                        }

                        @media (max-height: 770px) {

                            img {
                                top: -60px;
                            }
                        }

                        @media (max-height: 710px) {

                            img {
                                top: -120px;
                            }
                        }

                        @media (max-height: 650px) {

                            img {
                                top: -170px;
                            }
                        }
                    }

                    &[data-native-app="false"] {

                        &>img {
                            margin-top: -120px;
                        }
                    }
                }

                &.options {
                    align-self: flex-end;

                    a, button {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }

                    a {
                        font-weight: 500;
                        text-align: center;
                        color: white;
                        text-decoration: none;
                    }

                }

            }

        }

    }

    .testing-warning, .testing-danger {
        background: #ed6e17;
        padding: 15px;
        color: white;
        font-weight: 700;
        border-radius: 12px;
        margin-top: 32px;
        position: absolute;
        top: 40px;
        width: calc(100% - 60px);
        left: 15px;
        z-index: 2;

        &.testing-danger {
            background: $red
        }
    }

    @media (min-width: 400px) {

        .hero {
            padding: 20px;

            &>img {
                left: 0 !important;
                max-width: 400px !important;
            }
        }   
    }

    @media (min-width: 720px) and (min-height: 600px) {
        
        .hero[data-native-app="false"] {
            display: flex;
            height: 100vh;
            padding: 0;
            align-items: center;
            justify-content: center;

            h1 {
                display: flex !important;
            }

            &>img {
                margin-top: unset !important;
                width: 340px !important;
                margin: 0 !important;
                position: static !important;
                margin-right: 40px;
            }

            .bottom {
                margin-left: 40px;

                button {
                    border-radius: 12px;
                }

                .downloads {
                    max-width: unset;

                    img {
                        margin: 0 !important;
                        display: inline-block;
                        height: 48px !important;
                        width: unset !important;

                        &:first-of-type {
                            margin-right: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

.swiper-wrapper-outer {
    height: 100%;
    // overflow: hidden;
    background-color: $light-blue-2;

    &[data-section="postalcode"],
    &[data-section="publicprofile"]
    {
        
        .progress-header {
            background-color: unset;   
        }
    }

    &>div {
        width: 100%;
        height: 100%;

        .swiper-container {
            height: 100%;
            overflow: visible;

            .keyboard-hide {
                position: absolute !important;
                padding: 10px 8px 6px 8px !important
            }

            .swiper-wrapper {
                height: 100%;

                .swiper-slide {
                    overflow: hidden;

                    .slide-wrapper {
                        height: 100%;
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .slide-content {
                            flex-basis: 100%;
                            height: 100%;
                            text-align: center;

                            section {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                margin: auto;
                                max-width: 430px;

                                &>* {
                                    flex-basis: 100%;
                                }

                                h4 {
                                    font-size: 18px;
                                    font-weight: 500;
                                    color: black;
                                    margin: 0 0 12px 0;
                                }

                                header {
                                    padding: 0 15px;
                                    position: absolute;
                                    top: 60px;
                                    left: 0;
                                    width: calc(100% - 30px);
                                    background-color: $light-blue-2;
                                    z-index: 2;

                                    p {
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: white;
                                        font-weight: 500;
                                        margin-bottom: 0;
                                    }

                                    h1 {
                                        color: white;;
                                    }
                                }

                                .center {
                                    align-self: center;
                                    flex-wrap: wrap;
                                    height: unset;
                                }

                                &[data-section="completed"] {

                                    header {
                                        display: none;
                                    }

                                    .center {
                                        max-height: calc(100vh - 180px);
                                        overflow: auto;

                                        .profile {
                                            text-align: center;
                                            margin-bottom: 15px;
                                            padding: 0 15px 180px 15px;

                                            img {
                                                width: 80px;
                                                height: 80px;
                                                border-radius: 100%;
                                                display: inline-block;
                                            }

                                            h3 {
                                                margin: 0 0 0 0;
                                                color: white;
                                            }

                                            p {
                                                margin: 0 0 15px 0;
                                                color: white;
                                            }

                                            .skills {

                                                .skill {
                                                    background-color: white;
                                                    color: black;
                                                    margin: 0 6px 6px 0;
                                                    border-radius: 4px;
                                                    padding: 4px 6px;
                                                    display: inline-flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: 15px;
                                                    line-height: 15px;
                                                }
                                            }
                                        }

                                        .tutorial-wrapper {
                                            position: absolute;
                                            background-color: $light-blue-2;
                                            left: 0;
                                            bottom: 80px;
                                            width: 100%;
                                            padding: 0 10px;
                                            width: calc(100% - 20px);

                                            .tutorial {
                                                background-color: rgba(0,0,0,0.15);
                                                padding: 10px 0 0 0;
                                                border-radius: 20px;
                                                max-width: 340px;
                                                
                                                margin: auto;
                                            }
                                        }
                                    }
                                }

                                &[data-section="intro"] {
                                    overflow: auto;
                                }

                                &[data-section="intro"] {

                                    header {
                                        align-self: flex-start;
                                        position: static !important;
                                        padding-top: 20px;

                                        p {
                                            margin: 5px 0 20px 0;
                                            font-size: 20px;
                                        }

                                        h1 {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .center {
                                        align-self: center;
    
                                        .advantages {
    
                                            &>div {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                padding: 0 20px;
                                                margin-bottom: 10px;
    
                                                .circle {
                                                    flex-basis: 24px;
                                                    width: 24px;
                                                    height: 24px;
                                                    background-color: $green-2;
                                                    border: 2px solid white;
                                                    border-radius: 100%;
                                                    display: inline-flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                        
                                                    ion-icon {
                                                        color: white;
                                                        font-size: 18px;
                                                    }
                                                }
    
                                                p {
                                                    padding-left: 12px;
                                                    flex-basis: calc(100% - 60px);
                                                    text-align: left;
                                                    margin: 0;
                                                    color: white;
                                                }
                                            }
                                        }
                                    }

                                    .bottom-options {
                                        position: static !important;
                                    }
                                }

                                .bottom-options {
                                    padding: 15px;
                                    align-self: flex-end;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    width: calc(100% - 30px);

                                    .notch-filler {
                                        background-color: white;
                                        position: absolute;
                                        width: 100%;
                                        height: 40px;
                                        bottom: -40px;
                                        left: 0;
                                    }

                                    ion-input {
                                        text-align: left !important;
                                        color: #333;
                                        padding: 10px 8px !important;
                                        width: calc(100% - 16px) !important;

                                        input {
                                            color: #333 !important;
                                        }
                                    }

                                    h3 {
                                        color: #333;
                                    }

                                    button {
                                        margin: 0;

                                        &.main {
                                            background-color: white;
                                            color: #222;
                                        }
                                    }
                                }

                                &[data-section="sector"] {

                                    .bottom-options {
                                        display: inline-flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-wrap: wrap;
                                        padding: 10px !important;
                                        width: calc(100% - 20px);

                                        .sector {
                                            border-radius: 16px;
                                            overflow: hidden;
                                            flex-basis: calc(50% - 26px);
                                            margin: 8px;
                                            cursor: pointer;
                                            border: transparent 5px solid;

                                            p {
                                                margin: 3px 0 8px 0;
                                                color: white;
                                            }

                                            &[data-selected="true"] {
                                                border: 5px solid white;
                                            }
                                        }
                                    }

                                    @media (min-width: 600px) {

                                        .bottom-options {
                                            flex-wrap: nowrap;
                                        }
                                    }

                                    @media (min-width: 1000px) {

                                        .sector {
                                            max-width: 250px;
                                        }
                                    }
                                }

                                &[data-section="image"] {

                                    .center {
                                        margin-top: 100px;
                                    }
                                    
                                    .availability-calendar {
                                        width: 100vw;
                                        position: relative;

                                        .arrow-wrapper {
                                            top: -40px;
                                            left: 20px;
                                        }

                                        img {
                                            display: block;
                                            max-width: unset;
                                            width: 480px;
                                        }

                                        &[data-preview-ready="true"] {

                                            &>img {
                                                width: 900px;
                                                margin-left: -95px;
                                            }

                                            .contacts {
                                                top: 53px;
                                                max-width: 220px;

                                                &>div {
                                                    padding: 9.5px;

                                                    p {
                                                        font-size: 18px;
                                                        max-width: 120px;
                                                    }

                                                    img {
                                                        width: 64px;
                                                        height: 64px;
                                                    }
                                                }
                                            }
                                        }

                                        .contacts {
                                            position: absolute;
                                            top: 29px;
                                            left: 8px;
                                            max-width: 172px;
                                            white-space: nowrap;
                                            overflow: hidden;

                                            &>div {
                                                display: flex;
                                                align-items: center;
                                                justify-content: left;
                                                padding: 6px 0;

                                                img {
                                                    width: 32px;
                                                    height: 32px;
                                                    border-radius: 100%;
                                                }

                                                p {
                                                    margin: 0;
                                                    padding-left: 12px;
                                                    color: white;
                                                    font-size: 16px;
                                                    line-height: 16px;
                                                }
                                            }
                                        }
                                    }

                                    @media (min-width: 480px) {

                                        .availability-calendar {
                                            width: 480px;
                                        }
                                    }
                                }

                                &[data-section="sectors"],
                                &[data-section="skills"]
                                {
                                    .sectors-search {
                                        margin-top: 20px;

                                        ion-searchbar {
                                            text-align: left;
                                            height: 42px !important;
    
                                            &>div {
                                                height: 46px !important;
                                            }
                                        }
                                    }

                                    .sector-selector {

                                        .sectors {
                                            &:nth-child(1) { // selected ones
                                                
                                                &>div[data-selected="true"] {
                                                    background-color: $green-2 !important;
                                                    color: white !important;
                                                }
                                            }
    
                                            &:nth-child(2) {
                                                max-height: 175px;
                                            }
                                        }
                                    }
                                }

                                &[data-section="sectors"] {

                                    .sector-selector {
                                        padding: 0 15px;
                                        width: 100%;

                                        .sector-skill-manager {
                                            
                                        }

                                        ion-searchbar {
                                            --background: white;
                                        }

                                        &[data-keyboard-open="true"] {

                                            ion-searchbar {
                                                --border-radius: 12px 12px 0 0;
                                                margin: 0 -15px 0 -15px !important;
                                                width: calc(100% + 30px);
                                            }
                                        }

                                        .sectors {

                                            &>div {
                                                margin-right: 8px;

                                                &[data-selected="false"] {
                                                    border: 1px solid white !important;
                                                    color: white !important
                                                }

                                                &[data-selected="true"] {
                                                    background-color: white !important;
                                                    color: #222 !important;
                                                    width: unset;
                                                    padding: 4px;

                                                    .head {

                                                        &>span {
                                                            visibility: unset !important;
                                                            width: unset !important;
                                                        }
                                                    }

                                                    .options {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .common-sectors {
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        max-width: 500px;
                                        padding: 0 15px;
                            
                                        &>div {
                                            flex-basis: calc(50% - 15px);
                                            border-radius: 20px;
                                            overflow: hidden;
                                            background: $light-blue !important;
                                            margin: 7.5px;
                                            
                                            img {
                                                display: block;
                                            }
                            
                                            p {
                                                margin: 5px 0 5px 0;
                                                font-weight: 700;
                                                color: white;
                                            }

                                            &[data-selected="true"] {
                                                border: white 5px solid;
                                                margin: 2.5px;
                                            }
                                        }
                                    }
                                }

                                &[data-section="headline"],
                                &[data-section="postalcode"],
                                &[data-section="publicprofile"]
                                {

                                    .bottom-options {

                                        button.main {
                                            background-color: $light-blue-2;
                                            color: white;
                                            border-radius: unset;
                                        }
                                    }
                                }

                                &[data-section="publicprofile"] {

                                    .center {

                                        .map-wrapper {
                                            z-index: 1;
                                            position: relative;
                                        }
                                    
                                        .profile-wrapper {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            position: absolute;
                                            width: 100vw;
                                            height: 100vh;
                                            top: 0;
                                            left: 0;
                                            z-index: 2;

                                            .inner {
                                                width: 240px;
                                                height: 240px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                position: relative;

                                                img.main {
                                                    width: 84px;
                                                    height: 84px;
                                                    border-radius: 100%;
                                                    position: relative;
                                                    z-index: 3;
                                                }
    
                                                .best-match, .skill, .postcode {
                                                    position: absolute;
                                                    top: 152px;
                                                    left: 50%;
                                                    transform: translate(-50%, 0);
                                                    background-color: $green;
                                                    color: white;
                                                    border-radius: 52px;
                                                    padding: 6px 10px;
                                                    z-index: 4;
                                                    font-size: 18px;
                                                    line-height: 18px;


                                                    &.skill, &.postcode {
                                                        background-color: $light-blue-2;
                                                        white-space: nowrap;
                                                    }

                                                    &.skill {
                                                        left: 188px;
                                                        top: 56px;
                                                    }

                                                    &.postcode {
                                                        left: 60px;
                                                        top: 105px;
                                                    }
                                                }

                                                .avatar {
                                                    position: absolute;
                                                    border-radius: 100%;
                                                    width: 36px;
                                                    height: 36px;
                                                    opacity: 0.8;
                                                    z-index: 2;

                                                    &[data-avatar="1"] { top: 0; left: 0; }
                                                    &[data-avatar="2"] { top: -32px; right: -52px; }
                                                    &[data-avatar="3"] { bottom: 20px; left: -40px; }
                                                    &[data-avatar="4"] { top: 10px; left: 52px; }
                                                    &[data-avatar="5"] { bottom: 10px; left: 80px; }
                                                    &[data-avatar="6"] { top: -120px; left: 100px; }
                                                    &[data-avatar="7"] { top: 40px; right: 40px; }
                                                    &[data-avatar="8"] { bottom: -80px; left: 40px; }
                                                    &[data-avatar="9"] { bottom: -120px; right: 20px; }
                                                    &[data-avatar="10"] { top: -80px; left: -40px; }
                                                    &[data-avatar="11"] { bottom: -10px; right: -10px; }
                                                    &[data-avatar="12"] { top: -100px; right: 0; }
                                                }
                                            }
                                        }
                                    }
                                    
                                    .bottom-options {
                                        border-radius: 24px 24px 0 0;
                                        // box-shadow: $default-box-shadow-2;
                                        padding: 5px 15px 15px 15px;
                                        background-color: white;
                                        z-index: 3;

                                        button:not(.main) {
                                            background-color: white;
                                            color: #222;
                                            display: block;
                                            width: 100%;
                                            padding: 15px;
                                            font-size: 18px;
                                        }

                                        button.main {
                                            border-radius: 52px;
                                        }

                                        .flex {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            button.main {
                                                flex-basis: calc(100% - 32px);
                                            }

                                            button:not(.main) {
                                                flex-basis: 32px;
                                                font-size: 32px;
                                                padding: 0 0 0 10px;
                                            }
                                        }
                                    }
                                }

                                &[data-section="skills"][data-added-one="true"] {

                                    .skill-manager-enhanced {
                                        align-self: flex-end;
                                        max-height: calc(100% - 120px);
                                        overflow: auto;

                                        .search-input-wrapper {
                                            padding: 15px;

                                            &[data-inline="true"] {
                                                
                                            }
                                        }

                                        .bottom-options {

                                            ion-input {
                                                --background: #e6e6e6;
                                                border-radius: 52px;
                                                --padding-start: 10px;
                                            }
                                        }

                                        .center {
                                            position: relative;
                                        }
                                    }
                                }

                                &[data-section="headline"],
                                &[data-section="postalcode"],
                                &[data-section="contacts"],
                                &[data-section="smsnotifications"],
                                &[data-section="skills"][data-added-one="false"]
                                {

                                    .bottom-options {
                                        background-color: white;
                                        border-radius: 24px 24px 0 0;
                                        // box-shadow: $default-box-shadow-2;
                                        padding: 0;
                                        width: 100%;
                                        position: relative;

                                        ion-textarea {
                                            text-align: left;
                                            color: black;
                                            padding: 5px 10px 0 10px;
                                        }
                                    }

                                    .center {
                                        position: absolute;
                                        left: 0;
                                        right: 0
                                    }
                                }

                                &[data-section="postalcode"] {

                                    .slider-wrapper {
                                        display: flex;
                                        justify-content: center;

                                        &>div {
                                            max-width: 320px;
                                            position: relative;

                                            .prompt-arrow {
                                                position: absolute;
                                                top: 2px;
                                                left: 18px;
                                                z-index: 2;
                                                color: #333;
                                                
                                                ion-icon  {
                                                    font-size: 32px;
                                                }

                                                &:not(:first-child) {
                                                    left: 78px;
                                                }
                                            }
                                        }
                                    }
                                
                                    .center {
                                        z-index: 1;
                                        top: 0;
                                    }

                                    header {
                                        display: none !important;
                                    }

                                    .bottom-options, header {
                                        z-index: 2;
                                    }

                                    .bottom-options {

                                        .map-options {
                                            position: absolute;
                                            top: -54px;
                                            right: 10px;
                                            display: flex;

                                            button {
                                                height: 40px;
                                                min-width: 40px;
                                                border-radius: 52px;
                                                background-color: $light-blue-2;
                                                color: white;
                                                display: inline-flex;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 18px;
                                                padding: 0 12px;

                                                &:not(:last-child) {
                                                    margin-right: 10px;
                                                }
                                            }
                                        }

                                        label {
                                            color: black;
                                            margin: -5px 10px 10px 10px;
                                            display: block;
                                            font-size: 18px;
                                            line-height: 24px;

                                            span.postcode {
                                                color: $light-blue-2;
                                                margin-left: 4px;
                                            }

                                            span.change {
                                                margin-left: 4px;
                                                text-decoration: underline;
                                                font-style: italic;
                                                display: inline-block;
                                            }
                                        }

                                        p {
                                            color: #333;
                                            margin: 0 10px;

                                            &.error {
                                                color: $red;
                                                margin-bottom: 10px;
                                                margin-top: -5px;
                                            }
                                        }
                                    }
                                }

                                &[data-section="contacts"] {

                                    h4 {
                                        color: white !important;

                                        &>label {
                                            z-index: 2;
                                            background-color: $green-2;
                                            padding: 4px 12px;
                                            border-radius: 12px;
                                            color: white;
                                            font-size: 15px;
                                            line-height: 17px;
                                            display: inline-block;
                                            margin-top: 5px;

                                            &.red {
                                                background-color: $red;
                                                margin: 24px 0px;
                                            }
                                        }
                                    }

                                    .center {
                                        top: 170px;
                                        height: calc(100% - 290px);
                                        overflow: auto;
                                    }

                                    .contacts {
                                        width: 100%;
                                        position: relative;

                                        .list {
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: center;

                                            &>div {
                                                margin: 5px;
                                                position: relative;
                                                width: 68px;
                                                display: inline-block;
                                                overflow: hidden;
    
                                                img {
                                                    border-radius: 100%;
                                                    display: block;
                                                    margin-bottom: 12px;
    
                                                    &.large {
                                                        width: 68px;
                                                        height: 68px;
                                                    }
    
                                                    &.small {
                                                        width: 24px;
                                                        height: 24px;
                                                        position: absolute;
                                                        top: 44px;
                                                        right: 0px;
                                                    }
                                                }

                                                span {
                                                    background-color: $green-2;
                                                    color: white;
                                                    border-radius: 52px;
                                                    display: block;
                                                    font-size: 11px;
                                                    padding: 2px 0px;
                                                    margin-top: 3px;
                                                }
    
                                                p, label {
                                                    margin: 0 0 2px 0;
                                                    font-size: 13px !important;
                                                    line-height: 15px !important;
                                                    display: block;
                                                    overflow: hidden;
                                                    color: white;
                                                    word-wrap: break-word;
                                                }
                                                
                                                label {
                                                    font-weight: 600;
                                                }
                                            }   
                                        }
                                    }
                                }

                                &[data-section="contacts"],
                                &[data-section="smsnotifications"],
                                &[data-section="qualifications"] {

                                    .bottom-options {
                                        padding: 15px 15px 10px 15px;

                                        button.main {
                                            background-color: $light-blue-2;
                                            color: white;
                                        }

                                        button:not(.main) {
                                            background-color: white;
                                            color: #222;
                                            display: block;
                                            width: 100%;
                                            padding: 15px;
                                            font-size: 18px;
                                        }
                                    }
                                }

                                &[data-section="qualifications"] {

                                    .center {

                                        .qualification-suggestions {
                                            margin: 0 20px;

                                            &>div {
                                                background-color: $green-2;
                                                color: white;
                                                padding: 6px 10px;
                                                border-radius: 8px;
                                                display: inline-block;
                                                margin: 6px;
                                                font-size: 18px;
                                                line-height: 18px;
                                            }
                                        }
                                    }

                                    .bottom-options {

                                        button {
                                            border-radius: 52px;
                                        }
                                    }

                                    .qualifications {
                                        width: 100%;

                                        .button {
                                            margin-top: 20px;
                                        }

                                        .list {

                                            .qualification-item {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                color: white;

                                                span {
                                                    display: inline-block;
                                                    flex-basis: 40px;
                                                    text-align: center;
                                                    font-weight: 300;
                                                    font-size: 20px;

                                                    &.options {
                                                        flex-basis: 80px;
                                                        display: inline-flex;
                                                        align-items: center;
                                                        justify-content: center;

                                                        ion-icon {
                                                            font-size: 22px;
                                                            padding: 4px;
                                                        }
                                                    }
                                                }

                                                div {
                                                    flex-basis: calc(100% - 80px);
                                                    text-align: left;
                                                    border-bottom: 1px solid white;
                                                    padding: 5px 0;

                                                    h3 {
                                                        margin: 0 0 0 0;
                                                        font-size: 20px;
                                                        line-height: 22px;
                                                    }

                                                    p {
                                                        margin: 5px 0 0 0;
                                                        font-size: 16px;
                                                        line-height: 20px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &[data-section="smsnotifications"] {

                                    .center {

                                        .sms-verification-info {

                                            img {
                                                width: 100px;
                                            }
    
                                            p {
                                                font-size: 24px;
                                                line-height: 32px;
                                                font-weight: 400;
                                                color: white;
                                                
                                                span {
                                                    font-weight: 700;
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    .otp-wrapper {
                                        max-width: 400px;
                                        width: 100%;
                                        margin: auto;

                                        &>div {
                                            display: block !important;

                                            input {
                                                background-color: gainsboro;
                                                border: none;
                                                border-radius: 8px;
                                                color: #333;
                                                height: 32px !important;
                                                width: 24px !important;
                                                margin-right: 5px !important;
                                                font-weight: 700;
                                            }
                                        }
                                    }

                                    .react-tel-input {
                                        width: calc(100% - 10px);
                                        border: none;
                                        box-shadow: $default-box-shadow;
                                        border-radius: 52px;
                                        overflow: hidden;
                                        padding: 5px;
                                        margin-bottom: 15px;

                                        .form-control {
                                            width: 100%;
                                            border: none;
                                            color: black;
                                        }

                                        .flag-dropdown {
                                            background-color: transparent;
                                            border: none;
                                        }
                                    }

                                    .alternative-options {

                                        button {
                                            display: flex !important;
                                            align-items: center;
                                            justify-content: center;

                                            ion-icon {
                                                font-size: 24px;
                                                margin-right: 10px;
                                            }

                                            span {
                                                font-size: 20px;
                                                line-height: 20px;
                                            }
                                        }
                                    }

                                    .bottom-options {
                                        padding-bottom: 15px;
                                    }
                                }

                                &[data-section="pushnotifications"],
                                &[data-section="skills"]
                                {

                                    .notification-banner {
                                        margin: 0;
                                    }

                                    .bottom-options {

                                        label {
                                            color: white;
                                        }

                                        button:not(.main) {
                                            margin-top: 15px;
                                            border: white 2px solid;
                                            color: white;
                                            display: block;
                                            width: 100%;
                                            padding: 15px;
                                            font-size: 18px;
                                            background-color: transparent;
                                            border-radius: 52px;
                                        }
                                    }
                                }

                                &[data-section="addevents"] {
                                    
                                    .onboarding-guide-prompt {

                                        ion-icon {
                                            display: none;
                                        }

                                        section {
                                            height: unset;
                                        }
                                    }

                                    #event-editor-drawer-content,
                                    .event-timeline-item-wrapper
                                     {
                                        text-align: left;
                                    }
                                }

                                &[data-section="skills"][data-added-one="false"] {

                                    .skill-manager-enhanced {
                                        align-self: flex-end;

                                        .center-initial {
                                            position: static;
                                        }
                                    }
                                }

                                &[data-section="skills"][data-added-one="true"] {

                                    .sector-subsector-list-wrapper {

                                        .skills {
                                            white-space: nowrap;
                                        }

                                        h2 {
                                            
                                        }

                                        .warning-banner {
                                            border-radius: 12px;
                                            margin: 5px 15px 0px 0px;

                                            span {
                                                margin: 0;
                                            }
                                        }
                                    }

                                    .sector-skill-manager {
                                        width: 100%;

                                        .sectors {
                                            max-height: unset;

                                            .suggestions {
                                                white-space: unset;
                                                max-height: 212px;
                                                overflow: scroll;
    
                                                div {
                                                    margin-bottom: 8px;
                                                }

                                                &[data-obscured="true"] {
                                                    opacity: 0.4;
                                                }
                                            }

                                            .ReactTags__tags.react-tags-wrapper {
                                               
                                                .ReactTags__selected {
                                                    align-items: center;

                                                    .ReactTags__tagInput {
                                                        width: 100%;
                                                        margin-bottom: 15px;
                                                    }
                                                }

                                                .ReactTags__remove {
                                                    right: -19px !important;
                                                }

                                                .tag-wrapper {
                                                    display: inline-block;
                                                    background-color: $green-2;
                                                    color: white;
                                                    padding: 4px 28px 4px 10px;
                                                    border-radius: 6px;
                                                    font-size: 0.86em;
                                                    border: 0;
                                                    margin-right: 8px;
                                                    width: fit-content !important;
                                                    flex-basis: unset !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                &[data-section="headline"] {

                                    .center {
                                        padding: 0 15px;
                                        bottom: 72px;

                                        &>div {
                                            text-align: left;

                                            &::after, &::before {
                                                background-color: $light-blue-2;
                                            }

                                            p {
                                                margin: 0;
                                            }

                                            &:not(:last-child) {
                                                margin-bottom: 12px;
                                            }
                                        }
                                    }

                                    .chars-left {
                                        position: absolute;
                                        bottom: -30px;
                                        right: 18px;
                                        z-index: 3;
                                        color: $amber;
                                        font-weight: 400;

                                        &.danger {
                                            font-weight: 700;
                                            color: $red;
                                        }
                                    }

                                    .bottom-options {

                                        button {
                                            background-color: white !important;
                                            color: $light-blue-2 !important;
                                        }
                                    }
                                }

                                &[data-section="headline"],
                                &[data-section="postalcode"],
                                &[data-section="smsnotifications"]
                                {

                                    .bottom-options {
                                        display: block !important;
                                    }
                                }

                                // header, .bottom-options {
                                //     display: block !important;
                                // }
                            }
                        }
                    }
                }
            }
        }
    }

    &[data-keyboard-open="true"] {

        header {
            display: none !important;
        }

        &:not([data-section="skills"]) {

            .bottom-options {
                display: none !important;
            }
        }

        .center {
            align-self: flex-end !important;
        }

        &[data-section="skills"][data-added-one="false"] {
            padding-top: 0 !important;

            section {
                align-items: flex-end !important;
            }

            // section[data-added-one="true"] {

            //     .skill-manager-enhanced {
            //         align-self: flex-start !important;
            //         max-height: 100% !important;
            //     }
            // }

            // &[data-os="ios"] {

            //     .bottom-options {
            //         position: absolute !important;
            //         bottom: 59px;
            //     }
            // }

            // &[data-os="android"] {

            //     .bottom-options {
            //         position: absolute !important;
            //     }

            //     .skill-manager-enhanced {
            //         align-self: flex-end !important;
            //         padding-bottom: 70px !important;
            //     }
            // }
        }
    }

    @media (min-width: 500px) {
        
        section[data-section="intro"],
        section[data-section="completed"] {

            .center {
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                // max-height: 800px;
            }
        }
    }
}

.qualification {
    padding: 16px;
    background: white;
    border-radius: 16px;
    overflow: auto;

    .keyboard-hide {
        position: absolute;
    }

    h2 {
        color: #333;
        text-align: center;
        margin: 0 0 20px 0;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .qualification-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        
        .options {
            text-align: right;

            button {
                width: unset;
                padding: 8px 12px;
                border: none;
                margin: 0;
                margin-right: 10px;

                ion-icon {
                    margin-right: 4px;
                }

                &:not(.main):not(.delete) {
                    background-color: whitesmoke;
                }
            }
        }
    }

    .details-preview {

        h3 {
            font-size: 1em;
            font-weight: 700;
            margin: 0 0 4px 0;
            line-height: 1.2em;
        }

        p {
            font-size: 0.96em;
            line-height: 1.1em;
            margin: 0
        }
    }

    .details {

        .field-read, .field .field-head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3, p {
                font-size: 1em;
                margin: 0;
                line-height: 1.2em;
                color: #333;
            }

            p {
                text-align: right;
                padding-left: 12px;
            }

            ion-input {
                background-color: gainsboro;
                --padding-start: 12px !important;
                border-radius: 8px;
                color: #333;
            }
        
            &>ion-input, .ion-input-group {
                max-width: calc(100% - 124px);
            }

            .ion-input-group {
                display: flex;
            }
            
            &:not(:last-child) {
                margin-bottom: 4px;
            }

            &:not(.field-read) {
                margin-bottom: 8px;

                h3 {
                    margin-right: 12px;
                }
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.swiper-button-next, .swiper-button-prev, .swiper-pagination {
    display: none;
}

.progress-header {
    align-items: center;
    justify-content: center;
    height: 44px !important;
    width: 100%;
    left: 0;
    position: absolute !important;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-blue-2;

    &>button {
        font-size: 20px;
        line-height: 20px;
        padding: 12px;
        background-color: transparent;

        &.back-btn {

        }
    }

    .progress-bar {
        border-radius: 52px;
        height: 44px;
        position: relative;
        flex-basis: 100%;

        span {
            position: absolute;
            left: 0;
            top: 13px;
            padding: 0 10px;
            text-align: center;
            width: calc(100% - 20px);
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            z-index: 2;
            color: white;
        }
    
        .current {
            height: 34px;
            position: absolute;
            left: 5px;
            top: 5px;
            border-radius: 52px;
            z-index: 1;
            overflow: hidden;
            min-width: 34px;
            transition: 0.3s width;
            background-color: $light-blue-2;
            width: 0;
        }
    
        .error {
            background-color: $red;
            height: 25px;
            z-index: 2;
            position: absolute;
            top: 0;
        }

        &.progress-bar-new {
            position: absolute;
            top: -20px;
            left: 0;
            height: 20px;
            width: 100%;
            border-radius: unset;
            background-color: #1C50B1;
            overflow: hidden;

            .current {
                height: 20px;
                border-radius: unset;
                top: 0;
                left: 0;
                background-color: #379777;
                overflow: visible;
            }

            .current::after {
                content: '';
                position: absolute;
                top: 3px;
                right: -9px;
                width: 0;
                height: 0;
                border-left: 9px solid #379777; 
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
            }
        }
    }

    &[data-section="completed"] {

        header {
            display: none !important;
        }

        .progress-bar {
            background-color: transparent;
            
            span {
                font-size: 28px;
                line-height: 28px;
                top: 8px;
            }
        }
        
        .back-btn, .skip-btn {
            font-weight: 300;
        }
    }
}