@import '../../Colors.scss';

.rating-preview {
    display: inline-block;
    position: relative;
    top: 2px;
    
    .stars {

    }

    div {
        padding: 0;
    }
}   