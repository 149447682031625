@import '../../Colors.scss';

.event[data-resolving="true"] {
    display: flex;

    .resolution-method-toggle {
        display: inline-flex;
        width: 135px;

        ion-segment {
            width: 135px;
    
            ion-segment-button {
                min-width: 40px;
                width: 45px;
                --padding-start: 0 !important;
                --padding-end: 0 !important;
    
                ion-label {
                    white-space: break-spaces !important; 
                    font-size: 0.9em;
                    line-height: 1.1em;
                }
            }
        }
    }

    .event-item {
        margin-left: 8px;
        width: calc(100% - 135px);
        overflow: hidden;

        .options {
            display: none;
        }

        .details {
            flex-basis: 100%;  

            h3 {
                width: 100%;
                white-space: nowrap;
            }
        }
    }

    &:not(:first-child) {
        margin-top: 15px;
    }
}

.resolved-state {
    padding-top: 32px;
    margin-left: 32px;
    position: relative;

    .upper-line-horizontal {
        width: 100%;
        height: 5px;
        background-color: #555;
        position: absolute;
        top: 14px;
        left: -34px;
        width: 170px;
    }

    .upper-line-vertical {
        height: 19px;
        width: 5px;
        position: absolute;
        top: 0;
        left: 131px;
        background-color: #555;
    }

    .event-item {
        position: relative;
        padding: 0px 6px 21px 6px;
        width: calc(100% - 20px) !important;

        .after-resolve-icon {
            position: absolute;
            left: -40px;
            top: 5px;
            transform: rotate(180deg);
            font-size: 24px;
        }

        .icon {
            // right: auto;
            // left: 100px;
            // bottom: auto;
            // top: 1px;
            z-index: 3;
            bottom: 0;
        }

        .deleted-warning {
            width: 100%;
            background-color: $red;
            height: 5px;
            position: absolute;
            top: 30px;
            left: 0;
            transform: rotate(4.5deg);
            z-index: 2;
        }

        .deleted-warning-label {
            background-color: $red;
            color: white;
            top: 4px;
            right: 4px;
            position: absolute;
            padding: 3px 6px;
            border-radius: 5px;
            line-height: 1;
            font-size: 13px;
        }

        &:last-child {

            .vertical-line {
                height: 40px !important;
                top: -20px !important;
                bottom: auto !important;
            }
        }
    }

    @media (max-width: 370px) { width: calc(100% - 50px) !important; }
    @media (max-width: 360px) { width: calc(100% - 60px) !important; }
    @media (max-width: 350px) { width: calc(100% - 70px) !important; }
    @media (max-width: 340px) { width: calc(100% - 80px) !important; }
    @media (max-width: 330px) { width: calc(100% - 90px) !important; }
}

.event-item {
    display: flex;
    background-color: #F8F8F8;
    align-items: center;
    padding: 8px 10px 8px 10px;
    border-radius: 8px;
    // overflow: hidden;
    border-left: 8px solid transparent;
    margin-bottom: 16px;
    box-shadow: $default-box-shadow;
    position: relative;

    .half-available {
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 50%;
        top: 0;
        left: -8px;
        border-radius: 12px 0 0 0;
    }

    .icon {
        position: absolute;
        bottom: 5px;
        right: 15px;
        height: 20px;
        z-index: 2;

        svg {
            width: 16px !important;
        }

    }

    .details {
        flex-basis: calc(100% - 38px);

        .autoinserted-collision {
            display: flex;
            margin: 0 0 4px 0;
            align-items: center;

            ion-icon {
                font-size: 24px;
                flex-basis: 24px;
            }

            span {
                display: inline-block;
                width: calc(100% - 24px);
                padding-left: 8px;
                font-size: 1em;
                font-weight: 700;
            }
        }

        h2 {
            font-size: 0.9em;
            font-weight: 600;
            margin: 2px 0 0 0;
            color: #222;
        }

        h3 {
            font-size: 0.9em;
            font-weight: 400;
            margin: 0;
            color: #222;
            
            &:first-child {
                width: calc(100% - 40px);
                margin: 3px 0 0 0;
            }

        }

    }

    .options {
        position: absolute;
        top: 6px;
        right: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;

        ion-icon {
            font-size: 1em;
            margin-right: 8px;
            color: $dark-blue;
        }

        .logos {
            height: 100%;
            margin-right: 10px;

            div {
                border-radius: 4px;
                width: 20px;
                height: 20px;
                overflow: hidden;
                display: inline-block;
    
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    // position: relative;
                    // top: 1px;
                }

                &:nth-child(2) {
                    margin-left: 10px;
                }
    
            }
    
        }

    }

    &[data-type="4"] {
        border-left-color: $green;

        .icon svg {
            width: 24px;
            top: -5px;
            position: relative;
        }

        .options ion-icon.just-edited {
            color: $green;
        }
    
    }

    &[data-type="0"], &[data-type="1"]  {
        border-left-color: $red;

        .icon svg {
            width: 20px;
        }

        .options ion-icon.just-edited {
            color: $red;
        }

    }

    &[data-type="2"], &[data-type="3"] {
        border-left-color: $amber;

        .icon svg {
            width: 18px;
        }

        .options ion-icon.just-edited {
            color: $amber;
        }

    }

    .resolved, .not-resolved {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #3cc34b;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1.4em;
        left: 0;
        top: 0;
        z-index: 1;

        p {
            margin-left: 8px;
        }

        &.not-resolved {
            background-color: $red;
            width: auto;
            right: 0;
            left: auto;
            padding: 0px 12px;
            border-radius: 8px;
            font-size: 15px;
        }
    }

    .state {
        position: absolute;
        width: calc(100% - 12px);
        bottom: 0;
        left: 0;
        color: white;
        line-height: 1;
        padding: 3px 6px;
        font-size: 14px;
        z-index: 1;
        white-space: nowrap;

        &[data-state="existing"] {
            background-color: $amber;
        }

        &[data-state="new"] {
            background-color: $light-blue-2;
        }
    }

    &[data-resolve-existing="true"] {

        .details {
            position: relative;
            left: -6px;
            top: -9px;

            h2 {
                margin-top: -2px;
            }
        }

        .icon {
            bottom: 0px;
        }
    }

    .outside-arrow-wrapper {
        position: absolute;
        left: -52px;
        bottom: 0px;
        height: 100%;
        width: 30px;

        .arrow-alt {
            top: 10px;
            position: absolute;
            right: 0;
        }

        .vertical-line {
            width: 5px;
            background-color: #555;
            position: absolute;
            bottom: 0px;
            right: 15px;
            height: calc(100% + 16px);
        }

        .horizontal-line {
            width: 20px;
            height: 5px;
            background-color: #555;
            position: absolute;
            top: 15px;
            right: 0;
        }
    }

    &[data-has-collisions="true"], &[data-collision-target="true"] {
        background-color: $light-blue-2;
        border-left: 0;

        * {
            color: white !important;
        }

        &[data-collision-target="true"] {
            background-color: $red;
        }
    }
}

@keyframes highlight {
    0% {
        background-color: #ffe05d
    }
    100% {
        background-color: #F8F8F8;
    }
  }