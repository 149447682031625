$light-blue: #509ffe;
$light-blue-2: #3573E6;
$dark-blue: #17333F;
$dark-blue-2: #1C50B1;
$green: #50D890;
$green-2: #1bb161;
$yellow: #B5BC0E;
$red: #FB5B5A;
$amber: #FFA400;
$light-blue: #509ffe;
$extra-light-black: #777;
$light-black: #333;
$light-grey: #D6D6D6;

$default-box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.12);
$default-box-shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);