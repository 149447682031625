@import '../../Colors.scss';

.ion-page[data-page="time-presets"] {
    
    .time-presets {
        padding: 15px;

        p {
            margin: 0 0 15px 0;
            text-align: center;
        }

        .list {

            .time-preset {
                border-radius: 6px;
                margin-bottom: 20px;
                background-color: whitesmoke;
                // box-shadow: $default-box-shadow;
                padding: 12px;

                .name {
                    position: relative;

                    ion-input {
                        --padding-top: 0;
                        --padding-end: 52px;
                    }

                    ion-icon {
                        top: 0;
                        right: 0;
                        position: absolute;
                        color: #333;

                        &.delete-btn {
                            color: $red;
                            z-index: 10;
                        }

                        &.edit-btn {
                            right: 28px;
                        }

                    }

                }  
                
                .duration {
                    display: flex;

                    div {
                        flex-basis: calc(50% - 18px);
                    }

                    label {
                        flex-basis: 36px;
                        text-align: center;
                    }

                }

                .default {
                    margin: 10px 0 0 0;
                    font-size: 0.9em;
                    font-weight: 300;

                    &.error {
                        color: $red;
                    }

                }
    
            }

        }

    }
    
}