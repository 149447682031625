.availability-circle {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-mask-image: radial-gradient(transparent 62%, black 62%);
    mask-image: radial-gradient(transparent 62%, black 62%);
    border-radius: 50%;
    z-index: 2;
}