@import './Colors.scss';

.ion-page[data-page="chat"] {

    // ion-toolbar {
    //     height: 0;
    //     overflow: hidden;
    //     --background: #3573E6; // $light-blue-2
    // }

    .contact-item {
        
        .tags {
            display: none;
        }
    }

    .file-list {
                            
        &>.body-file {
            display: inline-flex;
            margin: 0 10px 0 0;
            border-radius: 6px;
            background-color: $light-blue;
            color: white;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 72px;
            padding: 5px;
            overflow: hidden;
            position: relative;
            float: left;

            .remove-file-btn {
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 10;
                font-size: 34px;
                color: $red;
                background: white;
                border-radius: 100%;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                object-fit: cover;
                background-color: $light-blue;
            }

            &>div {
                text-align: center;
                position: relative;
                z-index: 1;
                
                span {
                    word-break: break-word;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center !important;
                    display: block;
                    max-height: 42px;
                    overflow: hidden;
                }

                ion-icon {
                    font-size: 20px;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
 
    #chats-wrapper {
        
        .chat-list {
            position: relative;
            overflow: auto;
            height: 100%;
            
            &>div:first-child {
                margin-top: 10px;
            }

            &>div:last-child:not(:first-of-type) {
                margin-bottom: 10px;
                padding-bottom: 72px;
            }

        }

        .category {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            background: gainsboro;
            margin-bottom: 10px;
            position: relative;

            div {
                align-items: center;
                display: flex;

                ion-icon {
                    margin-right: 8px;
                }
            }

            .badge {
                background: $light-blue-2;
                width: 24px;
                height: 24px;
                padding: 0;
                text-align: center;
                top: 12px;
                right: 32px;
            }
        }

        .active-thread {
            height: 100%;

            .chat-head {
                display: flex;
                align-items: center;
                padding: 10px 15px;
                background-color: whitesmoke;
                height: 40px;
                box-shadow: $default-box-shadow;

                ion-icon {
                    font-size: 1.32em;
                    margin-right: 6px;
                }
                
                .img {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 100%;

                    img {
                        width: 100%;
                    }

                }

                .chat-about {
                    padding-left: 16px;

                    .chat-with {
                        font-weight: 700;
                        line-height: 1.1em;
                        font-size: 1.1em;
                        margin-bottom: 6px;
                    }

                    .chat-status {
                        font-weight: 400;
                        font-size: 1em;
                        line-height:  1em;
                    }

                }

            }

            .messages-wrapper {
                overflow: auto;
                position: absolute;
                width: 100%;

                .messages {
                    position: relative;
                    padding: 15px 15px 0 15px;
                    height: 100%;
                    justify-content: flex-end;
                    flex-direction: column;

                    .bubble {
                        max-width: calc(100% - 60px);
                        font-size: 0.96em;
                        position: relative;
                        display: inline-block;
                        clear: both;
                        margin-bottom: 8px;
                        padding: 8px 10px;
                        vertical-align: top;
                        border-radius: 10px;
    
                        &:before {
                            position: absolute;
                            bottom: 9px;
                            display: block;
                            width: 8px;
                            height: 6px;
                            content: '\00a0';
                            transform: rotate(29deg) skew(-35deg);
                        }
    
                        &.you {
                            float: left;
                            color: white;
                            background-color: $light-blue-2;
                            align-self: flex-start;
                            animation-name: slideFromLeft;
                            &:before {
                                left: -3px;
                                background-color: $light-blue-2;
                            }
                        }
    
                        &.me {
                            float: right;
                            color: black;
                            background-color: #eceff1;
                            align-self: flex-end;
                            animation-name: slideFromRight;
                            &:before {
                                right: -3px;
                                background-color: #eceff1;
                            }
                        }
    
                        span {
                            display: block;
                            text-align: right;
                            font-weight: 300;
                            font-size: 0.8em;

                            ion-icon {
                                font-size: 16px;
                                position: relative;
                                top: 4px;
                            }

                        }   

                        &:last-child {
                            margin-bottom: 40px;
                        }
    
                    }
    
                }   

            }

            .new-message, .new-message-blocked {
                display: flex;
                flex-basis: space-between;
                align-items: center;
                justify-content: center;
                min-height: 58px;
                background-color: whitesmoke;
                box-shadow: 6px 0px 8px 2px rgba(0, 0, 0, 0.12);
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 6px 0;
                resize: none;

                .file-list {
                    position: absolute;
                    top: -92px;
                    width: 100%;
                    left: 0;
                    padding: 10px 10px 0 10px;
                    background: whitesmoke;
                    z-index: 2;
                }

                textarea {
                    background-color: white;
                    border-radius: 8px;
                    border: none;
                    flex-basis: calc(100% - 40px);
                    padding: 0 6px 0 36px;
                    margin: 0;
                    resize: none;
                    width: 100%;
                    margin: 0 12px;
                }

                .attachments {
                    flex-basis: 40px;
                    padding-top: 2px;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 50px;
                    z-index: 2;

                    ion-icon {
                        font-size: 1.3em;
                        color: #333;
                        margin: 0 12px;
                    }
                }

                .send-btn {
                    font-size: 1.7em;
                    margin-right: 10px;
                }

                &.new-message-blocked {
                    padding: 16px 0;

                    button {
                        width: 100%;
                        border-radius: 8px;
                        font-size: 16px;
                        padding: 12px;
                        margin-top: 16px;
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }

        }

        &[data-thread-open="true"] {
            --overflow: hidden !important;
        }

    }   

}

#desktop-wrapper {

    #chats-wrapper[data-thread-open="true"] {

        .chat-list {
            width: 300px;
            float: left;
            display: inline-block;
        }
    
        .active-thread {
            width: calc(100% - 301px);
            float: left;
            display: inline-block;
            border-left: 1px solid #cecece;

            .messages-wrapper, .new-message {
                position: relative;
            }

        }

        .bottom-fab-options {
            width: 300px;
            left: 0;
        }

    }

}