@import './Colors.scss';

.hirer-rater {
    padding: 15px;
    background-color: whitesmoke;

    .comments-preview {

        span {
            font-weight: 300;
            font-style: italic;
            font-size: 0.9em;
        }
    }

    h1 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 0 20px 0;

        span {
            display: inline-flex;
            background-color: $light-blue;
            color: white;
            font-size: 16px;
            line-height: 16px;
            padding: 8px 12px;
            border-radius: 52px;
            flex-basis: 50%;

            ion-icon {
                margin-right: 8px;
            }

            &:first-child {
                background-color: $red;
                margin-right: 10px;
            }
        }
    }

    .header {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        justify-content: space-between;

        .profile {
            display: inline-flex;
            align-items: center;
            position: relative;

            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;

                &.small {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 28px;
                    height: 28px;
                }
            }
            
            div {
                padding: 0 10px;
    
                h3 {
                    margin: 0 0 4px 0;
                    font-size: 1em;
                    line-height: 1em;
                }
    
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 0.9em;
                }
            }
        }
    }

    .side {
        min-width: 124px;

        .react-stars {
        
        }

        .date {
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            margin-top: -2px;
        }
    }

    .comments {

        .comment {
            position: relative;
            margin-bottom: 8px;

            ion-icon {
                z-index: 2;
                position: absolute;
                left: 6px;
                top: 8px;
                font-size: 20px;
            }

            textarea {
                position: relative;
                display: block;
                resize: none;
                background-color: white;
                padding: 4px 8px 4px 32px;
                width: calc(100% - 40px);
                border-radius: 4px;
                border: 1px solid gainsboro;
                z-index: 1;
                min-height: 28px;
            }
        }
    }
    
    .options {
        text-align: right;

        button {
            background-color: $light-blue;
            padding: 16px;
            border-radius: 4px;
            font-size: 14px;
            width: 100%;
            color: white;
            font-weight: 700;
        }
    }

    &[data-preview="true"] {
        margin-bottom: 10px;
        
        .header {
            background-color: transparent !important;
            padding: 0 !important;
            margin: 0 !important;
            margin-bottom: 15px !important;
        }
    }
}