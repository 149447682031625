@import './Colors.scss';

.tab-header {
    background-color: $light-blue-2;
    padding: 10px;
    width: 100%;

    .logo {
        width: 40px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .title {
        font-weight: 600;
        color: white;
        text-align: center;
        font-size: 1.1em;
        padding: 5px 0;
        width: calc(100% - 24px);
    }

    .options {
        position: absolute;
        left: 10px;
        top: 10px;
    }

}