.drag-indicator {
  width: 120px;
  height: 6px;
  border-radius: 21px;
  background: #e1e1e1;
  margin: 12px auto 0px auto;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  margin: 5px;
  border-radius: 12px;

  .swipeable-drawer-body {

      .content {
          // padding: 20px
      }

  }

}

body[data-notch="true"] {
  &>.MuiDrawer-root>.MuiPaper-root {
      margin-bottom: var(--ion-safe-area-bottom,0) !important;
  }
} 