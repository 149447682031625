@import "./Colors.scss";

.clear {
  clear: both
}

.from-me, .from-them {
    position: relative;
    padding: 10px 20px;

    &.has-img {

        img {
            position: absolute;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            z-index: 4;
        }
    }
}

.from-me {
    color: white;
    background: #0B93F6;
    border-radius: 25px;
    float: right;

    &.text-area {
        width: 100%;
        padding: 10px !important;
        margin-top: 10px;

        ion-textarea {
            --background: #3573E6;
            width: 100%;
            margin: 0;
            --border-radius: 16px;
            --padding-start: 10px;
        }
    }

    &.has-img {
        margin-right: 48px;

        img {
            right: -48px;
            bottom: 0px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: -2px;
        right: -7px;
        height: 20px;
        border-right: 20px solid #0B93F6;
        border-bottom-left-radius: 16px 14px;
        transform: translate(0, -2px);
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -2px;
        right: -56px;
        width: 26px;
        height: 20px;
        background: white;
        border-bottom-left-radius: 10px;
        transform: translate(-30px, -2px);
    }
}

.from-them {
    background: #E5E5EA;
    border-radius: 25px;
    color: black;
    float: left;

    &.has-img {
        margin-left: 48px;

        img {
            left: -48px;
            bottom: 0px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: -2px;
        left: -7px;
        height: 20px;
        border-left: 20px solid #E5E5EA;
        border-bottom-right-radius: 16px 14px;
        transform: translate(0, -2px);
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 3;
        bottom: -2px;
        left: 4px;
        width: 26px;
        height: 20px;
        background: white;
        border-bottom-right-radius: 10px;
        transform: translate(-30px, -2px);
    }
}