@import './Colors.scss';

.ion-page[data-page="settings"] {
    
    #settings-wrapper {

        .profile-settings-grid {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;

            .task {
                flex-basis: calc(33.3333% - 32px);
                border-radius: 16px;
                background-color: #555;
                padding: 12px;
                text-align: center;
                margin-bottom: 12px;
                position: relative;

                .icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    background-color: white;
                    border-radius: 100%;
                    margin: 0 auto 12px auto;
                    position: relative;

                    ion-icon {
                        color: #555;
                        
                        &:nth-child(2) {
                            color: $green;
                            position: absolute;
                            bottom: -8px;
                            right: -8px;
                        }
                    }
                }

                h4 {
                    margin: 0;
                    font-size: 0.9em;
                    color: white;
                    word-break: break-word;
                }

                label {
                    position: absolute;
                    top: 41px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    font-size: 0.8em;
                    background-color: $amber;
                    width: 84px;
                    color: white;
                    border-radius: 52px;
                }

                &:nth-child(2),
                &:nth-child(5),
                &:nth-child(8),
                &:nth-child(11) {
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }

        .header {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 20px 20px 0 20px;
            background-color: white !important;
            position: relative;

            .profile-img {
                flex-basis: 64px;
                width: 64px;
                height: 64px;
                border-radius: 100%;
                overflow: hidden;
                background-color: whitesmoke;
                position: relative;

                .circle {
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                }

                img {
                    width: 100%;
                    border-radius: 100%;
                }

            }

            .details {
                padding-left: 12px;
                flex-basis: calc(100% - 64px);

                h2 {
                    margin: 0 0 2px 0;
                    font-size: 1.2em;
                    line-height: 1.4em;
                    font-weight: 600;
                }

                h3 {
                    font-weight: 400;
                    font-size: 1em;
                    margin: 0 0 4px 0;
                    line-height: 1.1em;
                }

                p { 
                    margin: 0 0 4px 0;
                    font-size: 0.86em;
                    line-height: 1.2em;
                    font-weight: 300;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }

            }

            .rating, .verification {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-basis: 52px;

                ion-icon {
                    font-size: 1.5em;
                }

                span {
                    color: $green;
                    font-size: 1.1em;
                    margin-left: 4px;
                }

            }

        }

    }

    ion-list {
        padding-bottom: 20px;
    }

    ion-item {

        ion-icon {
            margin-right: 8px;
            color: #333;
        }

    }

    .inline-select {
        margin-bottom: 10px;
        width: 100%;
    }

    .color-picker-event-wrapper {
        padding: 0 20px 0 60px;

        h3 {
            font-size: 1em;
            margin-top: 0;
        }

        button {
            padding: 12px;
            margin: 10px 0 10px 0px;
            width: calc(100% - 20px);
            border-radius: 5px;
            background-color: #333;
            color: white;
        }

    }

    .color-picker {
        margin-top: 4px;

        &>div {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            margin-right: 6px;
            border: 2px solid white;

            &[data-selected="true"] {
                box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
            }

        }

    }

}