@import './Colors.scss';

#referrals-add-wrapper {
    position: relative;

    #hide-keyboard {
        position: absolute;
        top: 0px;
        left: 50%;
        padding: 10px;
        border-radius: 0 0 8px 8px;
        font-size: 16px;
        transform: translate(-50%, 0);
        background-color: $red;
        color: white;
        display: inline-flex;
        align-items: center;

        span {
            margin-left: 10px;
        }
    }

    .half-inputs {
        display: flex;
        width: calc(100% + 15px);
    }

    .error {
        color: $red;
        margin-top: 15px;
    }

    mark {
        color: white;
        margin-left: 0;
        margin-right: 0;
    }

    .contact-selected-wrapper {
        display: flex;
        margin-bottom: 10px;

        &>div {
            width: calc(100% - 120px) !important;
        }

        .contact-item {
            margin: 0;
            padding: 0;
    
            h2 {
                margin-bottom: 0 !important;
            }
        }

        button {
            background-color: $red;
            width: 120px;
            border-radius: 12px;
            font-size: 13px;
            padding: 10px;
        }
    }

    .content {
        height: 100%;
        display: flex;
        align-items: flex-end;

        section {
            flex-basis: 100%;
            padding: 0 15px 15px 15px;
            text-align: center;

            h2 {
                text-align: center;
                margin: 0 0 10px 0;
                font-size: 18px;
                line-height: 28px;
            }

            h3 {
                align-items: center;
                text-align: left;
                display: block;
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 10px;

                ion-icon {
                    flex-basis: 32px;
                    margin-right: 10px;
                    position: relative;
                    top: 4px;
                }
            }

            .form {

                ion-item {
                    --background: #f3f3f3;
                    margin-left: -20px;
                    width: calc(100% + 40px);
                }
            }

            .or {
                text-align: center;
                margin: 0;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 60px;
                    height: 2px;
                    background: #555;
                    right: calc(50% + 20px);
                    top: 50%;
                    position: absolute;
                }

                &::after {
                    content: "";
                    display: block;
                    width: 60px;
                    height: 2px;
                    background: #555;
                    left: calc(50% + 20px);
                    top: 50%;
                    position: absolute;
                }
            }

            .contact-list-btn {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: inline-block;
                }

                .logos {
                    height: 24px;
                    position: relative;
                    margin-left: 10px;
                    width: 72px;
        
                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border: 1px solid #dddddd;
        
                        &:nth-child(2) {
                            left: 11px;
                        }
        
                        &:nth-child(3) {
                            left: 22px;
                        }

                        &:nth-child(4) {
                            left: 33px;
                        }

                        &:nth-child(5) {
                            left: 44px;
                        }
                    }
                }
        
            }

            .selector {

                &>div {
                    padding: 10px;
                    border-radius: 16px;
                    text-align: center;
                    margin: 15px 0;
                    color: #333;
                    background-color: #d0d0d0;
                    position: relative;

                    &[data-selected="true"] {
                        font-weight: 700;
                        background-color: #3f79eb;
                    }

                    ion-icon {
                        position: absolute;
                        font-size: 20px;
                        padding: 14px;
                        
                        &.tooltip {
                            top: 0;
                            right: 0;
                        }

                        &.icon {
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            .options {
                display: flex;

                button:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
}

[data-url*="/referrals/add"] {

    &:not(#desktop-wrapper) {

        ion-tab-bar {
            height: 0 !important;
            overflow: hidden !important;
        }
    }
}