@import './Colors.scss';

.ion-page[data-page="me"] {
    
    #me-wrapper {

        &>.header {
            background-color: $light-blue-2;
            border-radius: 16px;
            padding: 10px;
            margin: 50px 10px 10px 10px;
            text-align: center;
            box-shadow: $default-box-shadow;

            &>img {
                border-radius: 100%;
                width: 64px;
                height: 64px;
                margin-top: -44px;
                box-shadow: $default-box-shadow;
            }

            h1 {
                color: white;
                margin: 4px 0 0 0;
            }
        }

        .me-stats {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            font-weight: 700;

            .me-stat {
                flex-basis: calc(50% - 21px);
                margin-right: 10px;
                background-color: white;// rgba(255, 255, 255, 0.2);
                margin-bottom: 0px;
                border-radius: 16px;
                padding: 8px;
                overflow: hidden;
                color: #333;
                position: relative;

                &>ion-icon {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                }

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8),
                &:nth-child(10),
                &:nth-child(12) {
                    margin-right: 0;
                }

                label {
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                }

                h3 {
                    font-size: 24px;
                    margin: 0;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 26px;

                    ion-icon {
                        font-size: 22px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .lists {
            margin-top: 28px;

            section {

                h2 {
                    padding: 0 10px;
                }

                &[data-section="ratings"] {

                }
            }
        }
    }

}