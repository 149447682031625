@import '../Colors.scss';

.oboarding-guide {
    
}

ion-app[data-url*="/onboarding-preferences"] {

    .onboarding-guide-prompt {
        width: 100%;
    }

    .tabs-inner {
        background: $light-blue-2;
    }
}

.onboarding-guide-prompt-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: 0.3s all;
    opacity: 1;
    z-index: 10000;
}

.onboarding-guide-prompt {
    position: absolute;
    margin-left: 10px;
    margin-right: 10px;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    z-index: 1000;
    transition: 0.3s all;
    z-index: 10001;

    .options {
        display: flex;

        button {

            &:nth-child(2) {
                margin-left: 8px;
            }
        }
    }

    &>ion-icon {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 3;
        font-size: 32px;
        padding: 8px;
    }

    section {
        position: absolute;
        width: calc(100% - 20px);

        .details {
            background-color: whitesmoke;
            box-shadow: 0 0px 6px rgba(60, 60, 60, 0.16), 0 1px 5px rgba(60, 60, 60, 0.23);
            padding: 15px;
            border-radius: 10px;
            width: calc(100% - 10px);
            position: relative;
            z-index: 2;
            // border: 2px solid #3573E6;

            h2 {
                margin: 0 0 8px 0;
                font-size: 1.3em;
                text-align: center;
            }

            p { 
                margin: 0;
                font-size: 1em;
                line-height: 1.3em;
                text-align: center;
            }

            .header {

                .image-wrapper {
                    width: 28px;
                    height: 28px;
                    background-color: $light-blue;
                    border-radius: 100%;
                    padding: 16px;
                    margin: auto;
                    margin-bottom: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 28px;

                        path {
                            fill: white
                        }

                    }

                }

            }

        }

        .arrow-wrapper {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -70px;
            z-index: 1;
    
            svg {
                width: 24px;

                path {
                    fill: #333;
                }

            }

            &[data-rotated="true"] {
                bottom: auto;
                top: -62px;
            }
    
        }

    }

    button {
        background-color: $light-blue;
        padding: 16px;
        color: white;
        border-radius: 52px;
        margin-top: 12px;
        width: 100%;
    }

    &[data-step="10"],
    &[data-step="11"],
    &[data-step="12"]
    {

        path {
            fill: white !important;
        }
    }
}

#desktop-wrapper {

    .onboarding-guide-prompt {

        @media (min-width: 600px) {

            &[data-step="1"]:not([data-alt="true"]) {
                left: 50% !important;
                transform: translate(-50%, 0) !important;
            }
    
            &[data-step="2"] {
                top: 30px !important;
                left: 30px !important;
                bottom: auto !important;

                .arrow-wrapper {
                    left: -20px !important;
                    transform: rotate(90deg) !important;
                    top: 44px !important;
                    bottom: auto !important;
                }
            }
        }
    }
}