.swipeable-drawer-body[data-drawer="contact-selector"] {

    h1 {
        margin: 15px 45px 15px 15px;
        font-weight: 700;
        font-size: 1.2em;
        color: black;
    }

    .close-btn {
        position: absolute;
        color: black;
        font-size: 1.8em;
        top: 0;
        right: 0;
        margin: 12px;
        z-index: 100;
    }

}