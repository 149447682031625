.ion-page[data-page="files"] {

    ion-segment {
        min-width: 154px;
        height: fit-content;
        height: 36px;
        margin-left: 10px;
    }

    ion-searchbar {
        padding-bottom: 0;
        height: 36px;
    }

    .filters {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 11;
        padding: 10px;
        background-color: whitesmoke !important;

        &>div {
            flex-basis: 50%;

            &>div {
                border-radius: 8px;
                border: none;

                &:last-of-type {

                    &>div:first-of-type {
                        max-height: 210px;
                    }
                    
                    &>div {

                        &>div {
                            background-color: white;
                        }
                    }
                }
            }
        }

        ion-searchbar {
            padding: 0;
            margin-left: 15px;
        }

        input {
            background-color: transparent;
            border-radius: 8px;
            padding: 8px 12px;
            border-color: transparent;
            background-color: white;
            min-width: 160px;
        }
    }
}