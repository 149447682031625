@import "../../Colors.scss";

.offer-item {
    border-bottom: 1px solid #ddd !important;

    h2 {
        padding-left: 0 !important;
    }

    ion-icon {
        color: $red;
    }

    .just-edited {
        position: absolute;
        right: 0;
        top: 0;
    }

    .picture {
        position: relative;

        img:not(.secondary) {
            position: relative;
            z-index: 1;
            border-radius: 8px !important;
        }

        .secondary {
            position: absolute;
            right: -8px;
            bottom: -8px;
            width: 24px !important;
            height: 24px !important;
            object-fit: cover;
            z-index: 2;
            border-radius: 4px !important;
        }
    }

    .offer-title {

        span {
            font-weight: 700;
            margin-right: 4px;
        }
    }

    .reply-btn {
        position: absolute;
        right: 5px;
        bottom: 0;
        display: inline-flex;
        padding: 3px 6px;
        align-items: center;
        border-radius: 4px;
        background-color: $light-blue-2;

        ion-icon {
            margin-right: -8px;
            color: white;
            font-size: 14px;
        }

        span {
            color: white;
        }
    }

    .deadline {
        background-color: #ff6e6d;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2px 4px !important;
        margin: 0 0 0 6px !important;
        border-radius: 4px;

        p {
            color: white !important;
            font-size: 10px;
            line-height: 10px;
            margin: 0 4px 0 0 !important;
        }

        ul {
            margin: 0;
            margin-block-start: 0em;
            margin-block-end: 0em;
            padding-inline-start: 0px;
            display: inline-flex;

            li {
                display: inline-block;
                font-size: 10px;
                line-height: 10px;
                list-style-type: none;
                color: white;
                text-transform: uppercase;
                // width: 52px;
                display: inline-flex;
                align-items: center;
                justify-content: left;
                // margin-right: 10px;
            }
    
            li span {
                display: block;
                font-size: 14px;
                font-weight: 700;
                line-height: 14px;
                // width: 16px;
                text-align: right;
                margin: 0 2px;
            }
        }
    }
}