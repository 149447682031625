@import './Colors.scss';

.more-accurate-search-guide {
    position: absolute;
    top: 8px;
    right: 8px;
    width: calc(100% - 56px);

    h2 {
        display: none;
        // color: white;
        // font-size: 1.2em;
        // text-align: center;
        // margin: 0 0 16px 0;
    }

    &>div {
        display: flex;

        button {
            flex-basis: 50%;
            background-color: $light-blue-2;
            color: white;
            border-radius: 8px;
            padding: 5px 8px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;

            // span, ion-icon {
            //     color: $light-blue-2;
            // }

            ion-icon {
                font-size: 1.8em;
                flex-basis: 20px;
            }

            span {
                font-size: 1.1em;
                padding-left: 8px;
                flex-basis: calc(100% - 20px);
                text-align: left;
            }

            &:first-child {
                margin-right: 10px;
            }
        }

    }

}

#contacts-wrapper {
    background: white;
    position: relative;

    ion-searchbar {

        .searchbar-input {
            padding-right: 116px;
        }
    }

    .clear-filters-btn {
        position: absolute;
        top: 18px;
        right: 16px;
        padding: 8px 10px;
        color: white;
        background-color: $red;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        ion-icon {
            margin-right: 4px;
            font-size: 14px;
        }
    }

    .add-btn {
        padding: 14px 10px !important;
        text-align: left;

        ion-icon {
            width: 22px;
        }

        &.limit-width {

            span {
                word-wrap: break-word;
                text-align: left !important;
                display: inline-block;
            }
        }

        &.limit-width[data-i="1"] span {
            width: 60px;
        }

        &.limit-width[data-i="2"] span {
            width: 76px;
        }

        &.limit-width[data-i="3"] span {
            width: 52px;
        }

        @media (max-width: 380px) {

            &.limit-width[data-i="2"] span {
                width: 42px;
            }
        }
    }

    .modal-loading-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $light-blue-2;
        width: 72px;
        height: 72px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        &>div {
            color: white;
        }
    }

    .online-search-preview {
        padding: 10px 10px 0 10px;

        .searching-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            &>div {
                margin-right: 10px;
            }
        }

        .results {
            max-height: 144px;
            overflow: hidden;

            .org-contact-pill {
                float: left;
                display: inline-flex;
                margin: 0 10px 10px 0;
                border-radius: 52px;
                color: white;
                align-items: center;
                height: 32px;
                overflow: hidden;
    
                .contact-image, .org-image {
                    height: 32px;
                    width: 32px;
                    border-radius: 52px;
                    display: block;
                }

                .contact-image:not(img) {
                    width: 0;
                }
    
                .name {
                    font-size: 14px;
                    line-height: 14px;
                    max-height: 26px;
                    max-width: 120px;
                    padding: 2px 8px;
                    overflow: hidden;
                }
    
                .contacts {
                    height: 32px;
                    position: relative;
    
                    .org-contact {
                        display: inline-flex;
                        align-items: center;
                        background-color: $light-blue-2;
                        border-radius: 52px;
                    }
                }

                &[data-org="true"] {
                    background-color: $light-blue-2;

                    .org-contact {
                        position: absolute;
                        top: 0;
                    }

                    .org-contact:nth-child(1) {
                        left: 0;
                        z-index: 1;
                    }

                    .org-contact:nth-child(2) {
                        left: 16px;
                        z-index: 2;
                    }

                    .org-contact:nth-child(3) {
                        left: 32px;
                        z-index: 3;
                    }
                }

                &[data-org="false"] {
                    margin-right: 0;

                    .org-contact {
                        margin-right: 8px;
                    }
                }
            }
        }
        
        .show-all-promo {
            margin: -0 -10px;
            background: $green-2;
            color: white;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            ion-icon {
                font-size: 2em;
            }
        }
    }

    .no-results-options {
        background-color: gainsboro;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            color: black;
            font-size: 1.2em;
            text-align: center;
            margin: 0;
        }

        button {
            background-color: $light-blue-2;
            color: white;
            border-radius: 12px;
            margin-left: 12px;
            padding: 8px 12px;
            font-size: 16px;
        }

        // &>div {
        //     display: flex;

        //     button {
        //         flex-basis: 50%;
        //         background-color: $light-blue-2;
        //         color: white;
        //         border-radius: 12px;
        //         padding: 6px 10px;
        //         display: inline-flex;
        //         align-items: center;
        //         justify-content: left;
        //         font-size: 16px;
        //         text-align: left;

        //         img {
        //             height: 24px;
        //         }

        //         span {
        //             margin-left: 10px;
        //             font-size: 14px;
        //             line-height: 16px;
        //         }

        //         &:first-child {
        //             margin-right: 12px;
        //         }
        //     }
        // }
    }

    .contact-list {
        height: 100%;
        overflow: auto;
        position: relative;

        .guide {

            p:last-child {
                margin-bottom: 52px;
            }

            .modal-guide {
                padding: 18px 15px 15px 15px;
                text-align: left;
                top: 64px;
                position: absolute;
                background: whitesmoke;
                margin: 0 10px;
                border-radius: 20px;
                box-shadow: $default-box-shadow;

                img {
                    max-width: 90px;
                }

                h2 {
                    margin: 0;
                    font-size: 20px;

                    span {
                        display: block;

                        &.or {
                            background: white;
                            margin: 5px -15px;
                            padding: 5px 20px;
                            text-align: center;                        
                        }
                    }
                }

                .line {
                    position: absolute;
                    top: -16px;
                    left: 10;
                    width: 5px;
                    height: 30px;
                    background-color: whitesmoke;
                    padding: 0;
                    z-index: 1;
                }
            }

        }

    }

    .bottom-fab-options[data-keyboard-open="true"] {
        bottom: 0px;
        left: 0;
        right: auto;
        width: unset;
        border-radius: 0;

        button:first-child {
            border-radius: 0 12px 0 0 !important;
            padding: 6px 12px 6px 8px;
        }

        button:nth-child(2) {
            display: none;
        }
    }

}   

ion-modal[data-modal="new-contact-modal"] {

    .content {

        .input-disabled.sc-ion-input-ios-h {
            opacity: 0.5;
        }

        .contact-suggestions {
            padding: 5px 0;
            background-color: whitesmoke;
            margin: 10px;
            border-radius: 10px;
            max-height: 223px;
            overflow: auto;
        
            h3 {
                color: black;
                font-size: 1em;
                text-align: center;
                margin: 0;
                font-weight: 700;
                padding: 5px 0;
            }
        }

        .sensitive-information-share {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            background-color: $light-blue-2;
            color: white;

            &>span {
                margin: 0;
                font-size: 0.8em;
                // flex-basis: calc(100% - 44px);
            }

            ion-icon {
                font-size: 32px;
                margin-right: 12px;
            }
        }

        #contact-type-selector {
            margin: 15px !important;
        }

        button.main {
            display: flex;
            justify-content: center;
            padding: 12px 12px !important;
            width: calc(100% - 30px);
            align-items: center;
            border-radius: 8px;
            line-height: 22px;

            img {
                width: 36px;
                margin-right: 10px;
            }
        }

        ion-item {

            img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                margin-right: 8px;
            }
        }

        .suggestions-wrapper {
            padding: 10px;

            p {
                margin: 0 0 10px 0;
                font-size: 0.9em;
                font-style: italic;
                text-align: center;
            }

            .suggestions {
                white-space: nowrap;
                overflow: scroll;
                overflow-y: hidden;
        
                &>span {
                    display: inline-flex;
                    align-items: center;
                    justify-content: left;
                    background-color: $light-blue;
                    color: white;
                    padding: 4px 10px 4px 4px;
                    border-radius: 52px;
                    font-size: 0.8em;
                    line-height: 1em;
                    margin-right: 8px;
                    max-width: 120px;
                    overflow: hidden;
                    white-space: break-spaces;
    
                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 4px;
                        border-radius: 100%;
                    }
    
                    &>span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-height: 28px;
                        display: inline-block;
                    }
                }
        
            }
        }
    
    }
}