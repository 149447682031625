.swipeable-drawer-body[data-drawer="recent-events"] {
    
    .header {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #recent-events-drawer-close-btn {
            color: black;
            font-size: 1.8em;
            margin-left: 12px;
        }

    }

    section {
        padding: 0 5px 15px 15px;

        .list {

            &>div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .event {
                    width: 100%;
                }

                .event-item {
                    flex-basis: calc(100% - 48px);
                }

                &>ion-icon {
                    flex-basis: 48px;
                    position: relative;
                    top: -10px;
                    font-size: 1.2em;
                }

            }

        }

    }

}