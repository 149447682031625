/* This CSS was inspired by the blue pulsing dot in Grammarly */
:root {
  --pulseSize: 20px;
  --blue: rgba(95,139,250,.65);
  --transparentBlue: rgba(95,139,250,0);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.pulse {
  box-shadow: 0 0 0 var(--blue);
  animation: pulsing 2s infinite;
  transition: all 0.2s;
  cursor: pointer;
}

.pulse:not(.no-size) {
  width: var(--pulseSize);
  height: var(--pulseSize);
  border-radius: 50%;
  background: var(--blue);
}

.pulse:hover {
  --blue: rgba(209, 167, 160,.65);
  --transparentBlue: rgba(209, 167, 160,0);
}

.pulse:active {
  transform: scale(1.5);
}

@keyframes pulsing {
  from {
    box-shadow: 0 0 0 0 var(--blue);
  }
  70% {
    box-shadow: 0 0 0 var(--pulseSize) var(--transparentBlue);
  }
  to {
    box-shadow: 0 0 0 0 var(--transparentBlue);
  }
}

.pulse-alt {
  animation: pulsing-alt 2s infinite;
}

@keyframes pulsing-alt {
  from {
    box-shadow: 0 0 0 0 var(--blue);
    transform: scale(1); 
  }
  50% {
    box-shadow: 0 0 0 var(--pulseSize) var(--transparentBlue);
    transform: scale(0.9);
  }
  to {
    box-shadow: 0 0 0 0 var(--transparentBlue);
    transform: scale(1);
  }
}
