@import './Colors.scss';

.offer-wrapper {

    h1 {
        margin: 15px 45px 15px 15px;
        font-weight: 700;
        font-size: 1.2em;
        color: black;
    }

    h2, p, .obligations {
        color: black;
    }

    h2 {
        font-size: 1.1em;
        line-height: 1.16em;
        font-weight: 500;
        margin: 0;
        margin-bottom: 2px;
        margin-top: 20px;
        padding: 0 20px
    }

    .details>p {
        padding: 0 20px;
    }

    h3 {
        font-weight: 700;
    }

    .obligations {
       padding: 20px;
       font-style: italic;
       text-align: center;
    }

    .contact-details {

        h2 {
            padding-left: 0;
        }

    }

    label {
        display: block;
        font-style: italic;
        margin: 15px 20px 0 20px;
    }

    .contacts {
        position: relative;
        margin-top: 24px;

        .add-agency-btn {
            background: $light-blue;
            position: absolute;
            right: 15px;
            top: -8px;
            z-index: 5;
            border-radius: 52px;
            padding: 12px 16px;
            font-weight: 500;
            font-size: 1em;
            color: white;
            display: block;
            margin-top: 0 !important;

            &.edit-agency-btn {
                right: 132px;
            }

        }

        .contact-item {
            display: none;
        }

        .company-header-item {
            padding-top: 10px;

            img {
                border-radius: 100%;
            }

            div {

                h2 {
                    margin-top: 4px !important;
                }

            }

        }

    }

    .switch {

        ion-icon {
            position: absolute;
            top: 0;
            right: 0;
            color: #333;
            font-size: 2em;
            padding: 9px 10px;
        }

    }

    .hirers-website {

        p {
            display: flex;
            align-items: space-between;
            align-items: center;
        }

        button {
            position: absolute;
            bottom: 15px;
            right: 15px;
            background-color: #333;
            color: white;
            border-radius: 5px;
            padding: 8px 12px;
            font-size: 1.1em;
            display: inline-flex;
            align-items: center;

            ion-icon {
                position: static;
                margin-right: 8px;
            }

        }
    }

    .countdown {
        background-color: #FF4A4A;
        text-align: center;
        padding: 16px 0 12px 0;

        h3, p {
            color: white !important;
            letter-spacing: 1px;
            font-size: 16px;
            padding: 0 10px;

            span {
                text-decoration: underline;
                display: inline-block;
                margin-left: 5px;
            }
        }

        h3 {
            text-transform: uppercase;
            margin: 0 0 4px 0;
        }

        p {
            font-size: 16px;
            margin: 0 0 16px 0;

            &:nth-of-type(1) {
                margin-bottom: 0;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-size: 16px;
                line-height: 16px;
                list-style-type: none;
                color: white;
                text-transform: uppercase;
                width: 80px;
            }
    
            li span {
                display: block;
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 4px;
                line-height: 28px;
            }
        }
    }
}

.bottom-buttons {
    align-items: center;
    width: calc(100% - 20px);
    display: flex;
    left: 10px;
    bottom: 20px;
    position: absolute;
    z-index: 100;
    justify-content: center;

    button {
        margin: 0 5px;
        padding: 0 12px;
        border-radius: 52px;
        font-size: 16px;
        font-weight: 700;
        background-color: $light-blue;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        color: white !important;

        &.success {
            background-color: $green;
        }

        &.danger {
            background-color: $red;
        }

        ion-icon {
            font-size: 20px;
            margin-right: 6px;
        }

        img {
            width: 28px;
            height: 28px;
            border-radius: 100%;
        }
    }
}
