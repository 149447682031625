@import "../../Colors.scss";

.event-editor-wrapper {
    flex-direction: unset;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
    position: absolute;

    ion-content[data-modal="entry"] {
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        --keyboard-offset: unset !important;
    }

    .content {
        padding-bottom: 0 !important;
        height: 100%;
    }

    ion-fab {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

}

.swipeable-drawer-body[data-drawer="event-editor"] {
    // max-height: calc(100vh - 120px);
    overflow: auto;

    .top-banner {
        margin-bottom: 15px !important;
        background-color: $red;
    }

    h1 {
        margin: 15px 45px 15px 15px;
        font-weight: 700;
        font-size: 1.2em;
        color: black;
    }

    #entry-editor-drawer-close-btn {
        position: absolute;
        color: black;
        font-size: 1.8em;
        top: 0;
        right: 0;
        margin: 12px;
        z-index: 100;
    }

    h2, ion-segment-button, label, .address, p {
        color: #333;
    }

    .attachment-remove-btn {
        position: absolute;
        top: -4px;
        right: 0;

        label {
            float: left;
            margin: 1px 5px 0 0;
        }
        
        ion-icon {
            font-size: 1.72em;
        }
    }

    .content {
        // position: relative;
        // height: calc(100% - 6px);
        // overflow: scroll;
        padding-bottom: 72px;
    }

    // .suggestions {
    //     margin-top: 8px;
    //     white-space: nowrap;
    //     overflow: auto;

    //     &>span {
    //         font-weight: 500;
    //         line-height: 0.9em;
    //         margin-right: 12px;
    //         font-size: 0.8em;
    //         background: $light-blue;
    //         color: white;
    //         border-radius: 52px;
    //         padding: 8px 12px;
    //         display: inline-block;

    //         &>span {
    //             font-weight: 700;
    //             line-height: 0.9em;
    //             font-size: 0.9em;
    //         }

    //     }

    // }

    .details {
        margin: 0 15px;

        #repeat-type-selector-wrapper {
            margin: 0 20px;
            position: relative;

            .line {
                position: absolute;
                left: 50%;
                top: -8px;
                transform: translate(0, -50%);
                width: 8px;
                height: 16px;
                background-color: #eee;
            }
        }

        &>div {
            margin-bottom: 15px;

            .info-tag {
                font-size: 0.9em;
                background: $light-blue;
                top: -3px;
                display: inline-block;
                padding: 6px 8px;
                border-radius: 6px;
                line-height: 1;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                margin-bottom: 8px;
                color: white;

                ion-icon {
                    margin-right: 8px;
                }
            
            }

            h2 {
                font-weight: 500;
                font-size: 1em;
                position: relative;

                span:not(.attachment-remove-btn) {
                    margin-left: 12px;
                    float: right;
                }

                span:not(.attachment-remove-btn) {
                    font-weight: 400;
                    font-size: 0.96em;
                }

                &[data-active="true"] {
                    background: $light-blue;
                    color: white;
                    padding: 7px 8px 6px 8px;
                    margin: 0;
                    border-radius: 52px;
                }
            
            }

            ion-input, ion-textarea {
                --padding-top: 0;
                --padding-bottom: 0;
                color: #333
            }

            .presets, .suggestions {
                margin-top: 12px;
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: scroll;

                &>div {
                    display: inline-block;
                    background-color: #E5E5E5;
                    color: #333;
                    padding: 4px 12px;
                    border-radius: 52px;
                    margin-right: 10px;
                    margin-bottom: 10px;

                    ion-icon {
                        position: relative;
                        top: 2px;
                    }

                    &[data-selected="true"] {
                        background-color: $light-blue;
                        color: white;
                    }
                
                }
            
            }

            .suggestions {
                position: absolute;
                z-index: 2;
                left: 0;
                top: 70px;
                width: 100%;
                max-height: 160px;
                border-radius: 8px;
                box-shadow: $default-box-shadow;
                background-color: white;
                overflow: auto;

                h3 {
                    padding: 8px 12px 6px 12px;
                    margin: 0;
                    font-size: 1em;
                    white-space: break-spaces;
                }

                &>div {
                    display: flex;
                    margin: 0;
                    background-color: transparent;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    span {
                        display: inline-block;

                        &:first-of-type {
                            flex-basis: calc(100% - 120px);
                            padding: 0 12px 0 0;
                            overflow: hidden;
                        }

                        &:last-of-type {
                            
                        }
                    }

                    .work-type {
                        width: 4px;
                        height: 20px;
                        border-radius: 8px;
                    }
                                            
                    .work-type[data-type="2"]:nth-of-type(2) {
                        position: absolute;
                        left: 12px;
                        top: 8px;
                        width: 3px;
                        height: 10px;
                    }
                }
            }

        }

        .accept-offers {
            display: flex;
            justify-content: space-between;

            h2 {
                margin: 6px 0;
            }

        }

        .pulse {
            position: absolute;
            right: 0;
            top: 26px;
        }

        .event-name {
            position: relative;

            textarea {
                resize: none;
                width: 100%;
                color: #333;
                padding: 0;
                margin: 0;
                border: none
            }

            ion-textarea {
                padding-right: 24px;
                scroll-margin: 32px;
            }

            ion-icon {
                color: #333;
                position: absolute;
                right: 0;
                top: 23px;
                font-size: 1.2em;
            }

        }

        .days {
            white-space: nowrap;
            overflow: auto;

            &>div {
                width: 68px;
                margin-bottom: 8px;
                background-color: whitesmoke;
                border-radius: 5px;
                text-align: center;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                padding: 8px 0;
                cursor: pointer;

                &>div {
                    line-height: 1;
                    flex-basis: 100%;

                    &:first-child {
                        font-size: 1em;
                        font-weight: 700;
                        margin-bottom: 4px;
                    }

                    &:nth-child(2) {
                        font-size: 0.8em;
                        font-weight: 400;
                        margin-bottom: 4px;
                    }

                    &:nth-child(3), &:nth-child(4) {
                        font-size: 0.6em;
                        font-weight: 300;
                    }
                }

                &[data-selected="true"] {
                    background-color: $light-blue;
                    color: white;
                }

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }

        .event-type {
            position: relative;

            svg {
                margin: 4px 0;
                max-height: 24px;
                max-width: 24px;
            }

            ion-segment-button.segment-button-checked {
                
                ion-label {
                    font-weight: 700;
                }

            }

            .selected-indicator {
                position: absolute;
                top: -10px;
                left: 82px;
                width: 152px;

                label {
                    font-weight: 300;
                    position: relative;
                    top: -9px;
                    margin-left: 6px;
                    font-size: 0.9em;
                    display: inline-block;
                }

                path {
                    fill: #333;
                }

            }

            .logos {
                position: absolute;
                top: -5px;
                right: 5px;
    
                div {
                    border-radius: 4px;
                    width: 28px;
                    height: 28px;
                    overflow: hidden;
                    display: inline-block;
        
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
    
                    &:nth-child(2) {
                        margin-left: 10px;
                    }
        
                }
        
            }

        }

        .attachments {
            display: block;
            white-space: nowrap;
            overflow-y: hidden;
            overflow-x: scroll;

            & > div {
                & > ion-icon {
                    font-size: 1.52em;
                    position: relative;
                    top: 5px;
                    color: #444;
                }
    
                & > button {
                    display: inline-flex;
                    background-color: $light-blue;
                    color: white;
                    align-items: center;
                    justify-content: left;
                    padding: 8px 10px;
                    border-radius: 52px;
                    font-size: 1em;
                    margin-left: 8px;
    
                    ion-icon {
                        margin-right: 6px;
                    }
    
                }
            }
        }

        .repeats {

            .header {
                display: flex;
                justify-content: space-between;

                h2 {
                    margin: 6px 0;
                }

            }

            .type {
                margin-top: 8px;
            }

            .end-date {
                position: relative;

                input {
                    color: black;
                    border: none;
                    background: none;
                    width: 100%;
                    position: absolute;
                    top: 40px;
                    z-index: 1;
                    opacity: 0;
                }

                p {
                    margin: 0;
                    position: relative;
                    z-index: 2;
                }

                .MuiFormControl-root {
                    margin-top: 0;
                    width: 100%;

                    .MuiInputBase-root {

                        &:after, &:before {
                            border: none;
                        }

                    }
                    
                }

            }

        }

        .location {

            .search-results {

                input {
                    padding: 8px 12px;
                    margin-top: 6px;
                    background-color: whitesmoke;
                    border-radius: 4px;
                    color: #333;
                    width: calc(100% - 20px);
                    border: none;
                }

                .list {
                    margin-top: 4px;
                }

            }

            .address {
                position: relative;

                div {
                    flex-basis: calc(100% - 24px);
                }

                &>ion-icon {
                    flex-basis: 24px;
                    font-size: 1.8em;
                    position: absolute;
                    top: -25px;
                    right: 0;
                }

                p {
                    margin: 0px 0px 5px 0;
                }

            }

        }

    }

    .error-msg {
        color: $red;
        margin: -72px 15px 80px 15px;
        font-weight: 700;
        text-align: center;
        font-size: 0.9em;
    }

    ion-fab {
        display: flex;
        width: calc(100% - 20px);
        align-items: center;
        justify-content: space-between;
        --color: white;

        ion-fab-button.save-btn {
            --background: #50D890;
            --background-activated: #50D890;
            --background-focused: #50D890;
            --background-hover: #50D890;
        }

        ion-fab-button.favorite-btn {
            --background: #D6D6D6;
            --background-activated: #D6D6D6;
            --background-focused: #D6D6D6;
            --background-hover: #D6D6D6;

            &[data-active="true"] {
                --background: #FFA400;
                --background-activated: #FFA400;
                --background-focused: #FFA400;
                --background-hover: #FFA400;
            }

        }

        ion-fab-button.delete-btn {
            --background: #FB5B5A;
            --background-activated: #FB5B5A;
            --background-focused: #FB5B5A;
            --background-hover: #FB5B5A;
        }

    }

}

.backdrop-no-scroll {

    .MuiDrawer-modal {
        display: none;
    }

}