@import '../Colors.scss';

.inline-select {
    display: flex;
    padding: 4px;
    background-color: #eee;
    border-radius: 12px;
    margin: 0 !important;

    &>div {
        flex-basis: 100%;
        padding: 10px 4px;
        border-radius: 10px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        
        label, svg {
            flex-basis: 100%;
            display: block;
            text-align: center;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        svg, ion-icon {
            margin-bottom: 6px !important;
            margin-top: 0 !important;
            max-height: 24px;
        }

        label {
            font-size: 0.86em;
            line-height: 0.86em;
        }

        &[data-selected="true"] {
            background-color: $light-blue-2;
            box-shadow: $default-box-shadow;

            label, ion-icon {
                color: white;
            }

            &[data-alt-selected="true"] {
                background-color: #5c5c5c;
            }
        }

        &[data-disabled="true"] {
            opacity: 0.3;
        }

    }
}